import axios from "axios";
import { verifyToken } from "../../../utils/Funciones";
const API_URL = `https://cineupp.com:8443/api/cuenta/`;

// Get all categories

const create = async (formData) => {
    const response = await axios.post(API_URL, formData, {
        headers: {
            Authorization: 'Bearer ' + verifyToken('adminToken')
        },
    });
    return response.data;
};

const getAll = async () => {
    const response = await axios.get(API_URL);
    return response.data;
};

const getBypage = async (query) => {
    const response = await axios.get(API_URL + `cuentasbypage${query}`, {
        headers: {
            Authorization: 'Bearer ' + verifyToken('adminToken')
        },
    });
    return response.data;
};

const verificarCodigo = async (query) => {
    const response = await axios.get(API_URL + `verificarcodigo${query}`);
    return response.data;
};

const contador = async () => {
    const response = await axios.get(API_URL + `contador`, {
        headers: {
            Authorization: 'Bearer ' + verifyToken('adminToken')
        },
    });
    return response.data;
};

const deleteCuenta = async (id) => {
    const response = await axios.delete(API_URL + 'cuenta/' + id, {
        headers: {
            Authorization: 'Bearer ' + verifyToken('adminToken')
        },
    });
    return response.data;
};

const update = async (formData) => {
    const response = await axios.patch(API_URL + 'cuenta', formData, {
        headers: {
            Authorization: 'Bearer ' + verifyToken('adminToken')
        },
    });
    return response.data;
};

const cuentaService = {
    create,
    getAll,
    getBypage,
    verificarCodigo,
    contador,
    update,
    deleteCuenta
};

export default cuentaService;
