import axios from "axios";
import { verifyToken } from "../../../utils/Funciones";
const API_URL = `https://cineupp.com:8443/api/social/`;

const create = async (formData) => {
    const response = await axios.post(API_URL, formData, {
        headers: {
            Authorization: 'Bearer ' + verifyToken('adminToken')
        },
    });
    return response.data;
};

const createorder = async (formData) => {
    const response = await axios.post(API_URL + 'createorder', formData, {
        headers: {
            Authorization: 'Bearer ' + verifyToken('userToken')
        },
    });
    return response.data;
};

const emitirOrder = async (formData) => {
    const response = await axios.post(API_URL + 'emitirorder', formData, {
        headers: {
            Authorization: 'Bearer ' + verifyToken('adminToken')
        },
    });
    return response.data;
};

const getPlataforms = async () => {
    const response = await axios.get(API_URL + 'plataforms', {
        headers: {
            Authorization: 'Bearer ' + verifyToken('adminToken')
        },
    });
    return response.data;
};

const getServices = async () => {
    const response = await axios.get(API_URL + 'services', {
        headers: {
            Authorization: 'Bearer ' + verifyToken('adminToken')
        },
    });
    return response.data;
};

const getBypage = async (query) => {
    const response = await axios.get(API_URL + `ventasbypage${query}`, {
        headers: {
            Authorization: 'Bearer ' + verifyToken('adminToken')
        },
    });
    return response.data;
};

const getByPageByUserId = async (query) => {
    const response = await axios.get(API_URL + `ventasbyuserId${query}`, {
        headers: {
            Authorization: 'Bearer ' + verifyToken('userToken')
        },
    });
    return response.data;
};

const contador = async () => {
    const response = await axios.get(API_URL + `contador`, {
        headers: {
            Authorization: 'Bearer ' + verifyToken('adminToken')
        },
    });
    return response.data;
};

const getServiceApiById = async (query) => {
    const response = await axios.get(API_URL + `servicesapi${query}`);
    return response.data;
};
const deletePlataform = async (id) => {
    const response = await axios.delete(API_URL + 'plataform/' + id, {
        headers: {
            Authorization: 'Bearer ' + verifyToken('adminToken')
        },
    });
    return response.data;
};

const deleteService = async (id) => {
    const response = await axios.delete(API_URL + 'service/' + id, {
        headers: {
            Authorization: 'Bearer ' + verifyToken('adminToken')
        },
    });
    return response.data;
};

const updatePlataform = async (formData) => {
    const response = await axios.patch(API_URL + 'plataform', formData, {
        headers: {
            Authorization: 'Bearer ' + verifyToken('adminToken')
        },
    });
    return response.data;
};

const createMessageReport = async (formData) => {
    const response = await axios.patch(API_URL + 'message_report', formData, {
        headers: {
            Authorization: 'Bearer ' + (verifyToken('adminToken') || verifyToken('userToken'))
        },
    });
    return response.data;
};

const updateSocialOrder = async (formData) => {
    const response = await axios.patch(API_URL + 'updatesocialorder', formData, {
        headers: {
            Authorization: 'Bearer ' + verifyToken('adminToken')
        },
    });
    return response.data;
};

const updateService = async (formData) => {
    const response = await axios.patch(API_URL + 'service', formData, {
        headers: {
            Authorization: 'Bearer ' + verifyToken('adminToken')
        },
    });
    return response.data;
};

const socialService = {
    create,
    createorder,
    emitirOrder,
    getBypage,
    getByPageByUserId,
    contador,
    getPlataforms,
    getServices,
    getServiceApiById,
    deletePlataform,
    deleteService,
    updatePlataform,
    updateService,
    createMessageReport,
    updateSocialOrder
};

export default socialService;
