import Wrapper from "./Wrapper";
import { useEffect, useState } from "react";
import moment from "moment";
import 'moment/locale/es';
import { useDispatch, useSelector } from "react-redux";
import { getBestSellerDashboard, getLast10VentasDashboard, getNewUsersDashboard, getRecargasDashboard, getVentasDashboard } from "../../store/features/slices/dashboardSlice";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Dropdown from "../../components/dropdown";
import { FaMoneyBillAlt, FaUsers, FaWallet } from "react-icons/fa";
import { FaEllipsisVertical, FaUserTag } from "react-icons/fa6";
import AnimatedNumber from "react-animated-number";
import { currencyFormat, generateBackgroundColor } from "../../utils/Funciones";
import Chart from 'react-apexcharts'

const Dashboard = ({ }) => {
    const dispatch = useDispatch();
    const { isMutation, recargas, bestseller, ventas, newusers, last10Ventas } = useSelector((state) => state.dashboardReducer);
    const { theme } = useSelector((state) => state.userReducer);
    const [recargasState, setRecargasState] = useState({
        today: true,
        month: false,
        year: false,
        total: false
    })

    const [ventasState, setVentasState] = useState({
        today: true,
        month: false,
        year: false,
        total: false
    })

    const [ventasTop5State, setVentasTop5State] = useState({
        today: true,
        month: false,
        year: false,
        total: false
    })

    const [ventasGcTop5State, setVentasGcTop5State] = useState({
        today: true,
        month: false,
        year: false,
        total: false
    })

    const [ventasSocialTop5State, setVentasSocialTop5State] = useState({
        today: true,
        month: false,
        year: false,
        total: false
    })

    const [newusersState, setNewUsersState] = useState({
        today: true,
        week: false,
        month: false,
        year: false,
        total: false
    })

    useEffect(() => {
        dispatch(getBestSellerDashboard())
        dispatch(getVentasDashboard())
        dispatch(getRecargasDashboard())
        dispatch(getNewUsersDashboard())
        dispatch(getLast10VentasDashboard())
    }, [dispatch]);

    return (
        <Wrapper>
            {/* HEADER PAGES */}
            <div className="fixed pl-3 md:pl-3 lg:pl-3 h-8 top-11 z-30 flex items-center w-8/12 text-brand-900 dark:text-brand-100 text-[1.2rem] md:text-[1.8rem] lg:text-[2rem] font-semibold">
                Panel administrador
            </div>
            <div className="w-full md:w-12/12 mb-5 mt-12">
                <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-4">
                    <div className="w-full !z-5 relative flex flex-col rounded-[20px] bg-white bg-clip-border shadow-3xl shadow-shadow-500 dark:!bg-brand-900 text-brand-950 dark:text-white dark:shadow-brand-950">
                        <div className="absolute -bottom-2 right-10">
                            <LazyLoadImage
                                className="h-28 w-16 object-cover"
                                alt={'No image'}
                                effect="opacity"
                                src={`/images/helpers/trophy.png`}
                            />
                        </div>

                        <div className="flex items-center mt-4">
                            <div className="ml-4 flex w-auto flex-col justify-center">
                                <p className={`text-2xl text-left font-semibold text-lightPrimary dark:text-darkPrimary`}>
                                    Felicitaciones {bestseller?.name}! 🎉
                                </p>
                                <h4 className="font-dm text-sm font-medium text-lightPrimary/90 dark:text-darkPrimary/90">
                                    Mejor vendedor de {moment().format('MMMM')}
                                </h4>
                            </div>
                        </div>
                        <div className="flex items-center">
                            <div className="ml-[18px] flex h-[80px] w-auto flex-row items-center">
                                <div className="rounded-full p-3 bg-indigo-100 dark:bg-indigo-950">
                                    <span className="flex items-center text-indigo-500 dark:text-indigo-500">
                                        <FaUserTag size={32} />
                                    </span>
                                </div>
                            </div>

                            <div className="ml-4 flex w-auto flex-col justify-center">
                                <h4 style={{ fontFamily: 'Montserrat, sans-serif', fontStyle: 'normal', letterSpacing: 0.2 }} className="text-lg font-bold text-navy-700 dark:text-white">
                                    {
                                        <AnimatedNumber
                                            component="text"
                                            initialValue={bestseller?.total}
                                            value={bestseller?.total}
                                            stepPrecision={0}
                                            style={{
                                                transition: "0.8s ease-out",
                                                transitionProperty: "background-color, color, opacity"
                                            }}
                                            duration={2000}
                                            formatValue={n => 'S/ ' + n.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                        />
                                    }
                                </h4>
                            </div>
                        </div>
                    </div>
                    <div className="w-full !z-5 relative flex flex-col rounded-[20px] bg-white bg-clip-border shadow-3xl shadow-shadow-500 dark:!bg-brand-900 text-brand-950 dark:text-white dark:shadow-brand-950">
                        <div className="relative z-5 w-full flex items-center justify-end top-0 py-4 px-4">
                            <div className="w-[calc(calc(100%-1.25rem))] text-2xl text-left font-semibold">Ventas</div>
                            <Dropdown
                                closeOnChildren={true}
                                button={<div className="hover:bg-brand-100 active:bg-brand-200 dark:hover:bg-brand-800 dark:active:bg-brand-700 transition-colors ease-in-out duration-300 rounded-full p-2"><FaEllipsisVertical className="w-5 h-5 text-right cursor-pointer text-brand-900 dark:text-brand-100" /></div>}
                                children={
                                    <div className="flex w-32 flex-col justify-start bg-white bg-cover bg-no-repeat shadow-lg shadow-brand-300 dark:!bg-brand-950 dark:text-white dark:shadow-black">
                                        <div className="flex flex-col">
                                            <div onClick={() => setVentasState({ ...ventasState, month: false, year: false, total: false, today: true })} className="text-sm font-medium transition duration-150 ease-out hover:ease-in cursor-pointer hover:bg-brand-100 dark:hover:bg-brand-800 px-3 py-2">
                                                Hoy
                                            </div>
                                            <div onClick={() => setVentasState({ ...ventasState, month: true, year: false, total: false, today: false })} className="text-sm font-medium transition duration-150 ease-out hover:ease-in cursor-pointer hover:bg-brand-100 dark:hover:bg-brand-800 px-3 py-2">
                                                Este mes
                                            </div>
                                            <div onClick={() => setVentasState({ ...ventasState, month: false, year: true, total: false, today: false })} className="text-sm font-medium transition duration-150 ease-out hover:ease-in cursor-pointer hover:bg-brand-100 dark:hover:bg-brand-800 px-3 py-2">
                                                Este año
                                            </div>
                                            <div onClick={() => setVentasState({ ...ventasState, month: false, year: false, total: true, today: false })} className="text-sm font-medium transition duration-150 ease-out hover:ease-in cursor-pointer hover:bg-brand-100 dark:hover:bg-brand-800 px-3 py-2">
                                                Total
                                            </div>
                                            <div className="text-sm font-medium transition duration-150 ease-out hover:ease-in cursor-pointer hover:bg-brand-100 dark:hover:bg-brand-800 px-3 py-2">
                                                Más detalles
                                            </div>
                                        </div>
                                    </div>
                                }
                                animation="origin-[65%_0%] md:origin-top-right transition-all duration-300 ease-in-out"
                                classNames={"!absolute right-4 top-9"}
                            />
                        </div>
                        <div className="flex items-center">
                            <div className="ml-[18px] flex h-[80px] w-auto flex-row items-center">
                                <div className="rounded-full p-3 bg-green-100 dark:bg-green-950">
                                    <span className="flex items-center text-green-500 dark:text-green-500">
                                        <FaMoneyBillAlt size={32} />
                                    </span>
                                </div>
                            </div>

                            <div className="ml-4 flex w-auto flex-col justify-center">
                                <p className={`font-dm text-sm font-medium text-brand-600 dark:text-brand-400`}>
                                    {
                                        ventasState.today ?
                                            moment().format('[Hoy] dddd') :
                                            ventasState.month ?
                                                moment().format('MMMM') :
                                                ventasState.year ?
                                                    moment().format('YYYY') :
                                                    'Total'
                                    }
                                </p>
                                <h4 style={{ fontFamily: 'Montserrat, sans-serif', fontStyle: 'normal', letterSpacing: 0.2 }} className="text-lg font-bold text-navy-700 dark:text-white">
                                    {
                                        ventasState.today ?
                                            <AnimatedNumber
                                                component="text"
                                                initialValue={ventas?.today}
                                                value={ventas?.today}
                                                stepPrecision={0}
                                                style={{
                                                    transition: "0.8s ease-out",
                                                    transitionProperty: "background-color, color, opacity"
                                                }}
                                                duration={2000}
                                                formatValue={n => 'S/ ' + n.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                            /> :
                                            ventasState.month ?
                                                <AnimatedNumber
                                                    component="text"
                                                    initialValue={ventas?.month}
                                                    value={ventas?.month}
                                                    stepPrecision={0}
                                                    style={{
                                                        transition: "0.8s ease-out",
                                                        transitionProperty: "background-color, color, opacity"
                                                    }}
                                                    duration={2000}
                                                    formatValue={n => 'S/ ' + n.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                                /> :
                                                ventasState.year ?
                                                    <AnimatedNumber
                                                        component="text"
                                                        initialValue={ventas?.year}
                                                        value={ventas?.year}
                                                        stepPrecision={0}
                                                        style={{
                                                            transition: "0.8s ease-out",
                                                            transitionProperty: "background-color, color, opacity"
                                                        }}
                                                        duration={2000}
                                                        formatValue={n => 'S/ ' + n.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                                    /> :
                                                    <AnimatedNumber
                                                        component="text"
                                                        initialValue={ventas?.total}
                                                        value={ventas?.total}
                                                        stepPrecision={0}
                                                        style={{
                                                            transition: "0.8s ease-out",
                                                            transitionProperty: "background-color, color, opacity"
                                                        }}
                                                        duration={2000}
                                                        formatValue={n => 'S/ ' + n.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                                    />
                                    }
                                </h4>
                            </div>
                        </div>
                    </div>
                    <div className="w-full !z-5 relative flex flex-col rounded-[20px] bg-white bg-clip-border shadow-3xl shadow-shadow-500 dark:!bg-brand-900 text-brand-950 dark:text-white dark:shadow-brand-950">
                        <div className="relative z-5 w-full flex items-center justify-end top-0 py-4 px-4">
                            <div className="w-[calc(calc(100%-1.25rem))] text-2xl text-left font-semibold">Recargas</div>
                            <Dropdown
                                closeOnChildren={true}
                                button={<div className="hover:bg-brand-100 active:bg-brand-200 dark:hover:bg-brand-800 dark:active:bg-brand-700 transition-colors ease-in-out duration-300 rounded-full p-2"><FaEllipsisVertical className="w-5 h-5 text-right cursor-pointer text-brand-900 dark:text-brand-100" /></div>}
                                children={
                                    <div className="flex w-32 flex-col justify-start bg-white bg-cover bg-no-repeat shadow-lg shadow-brand-300 dark:!bg-brand-950 dark:text-white dark:shadow-black">
                                        <div className="flex flex-col">
                                            <div onClick={() => setRecargasState({ ...recargasState, month: false, year: false, total: false, today: true })} className="text-sm font-medium transition duration-150 ease-out hover:ease-in cursor-pointer hover:bg-brand-100 dark:hover:bg-brand-800 px-3 py-2">
                                                Hoy
                                            </div>
                                            <div onClick={() => setRecargasState({ ...recargasState, month: true, year: false, total: false, today: false })} className="text-sm font-medium transition duration-150 ease-out hover:ease-in cursor-pointer hover:bg-brand-100 dark:hover:bg-brand-800 px-3 py-2">
                                                Este mes
                                            </div>
                                            <div onClick={() => setRecargasState({ ...recargasState, month: false, year: true, total: false, today: false })} className="text-sm font-medium transition duration-150 ease-out hover:ease-in cursor-pointer hover:bg-brand-100 dark:hover:bg-brand-800 px-3 py-2">
                                                Este año
                                            </div>
                                            <div onClick={() => setRecargasState({ ...recargasState, month: false, year: false, total: true, today: false })} className="text-sm font-medium transition duration-150 ease-out hover:ease-in cursor-pointer hover:bg-brand-100 dark:hover:bg-brand-800 px-3 py-2">
                                                Total
                                            </div>
                                            <div className="text-sm font-medium transition duration-150 ease-out hover:ease-in cursor-pointer hover:bg-brand-100 dark:hover:bg-brand-800 px-3 py-2">
                                                Más detalles
                                            </div>
                                        </div>
                                    </div>
                                }
                                animation="origin-[65%_0%] md:origin-top-right transition-all duration-300 ease-in-out"
                                classNames={"!absolute right-4 top-9"}
                            />
                        </div>
                        <div className="flex items-center">
                            <div className="ml-[18px] flex h-[80px] w-auto flex-row items-center">
                                <div className="rounded-full p-3 bg-yellow-100 dark:bg-yellow-950">
                                    <span className="flex items-center text-yellow-500 dark:text-yellow-500">
                                        <FaWallet size={32} />
                                    </span>
                                </div>
                            </div>

                            <div className="ml-4 flex w-auto flex-col justify-center">
                                <p className={`font-dm text-sm font-medium text-brand-600 dark:text-brand-400`}>
                                    {
                                        recargasState.today ?
                                            moment().format('[Hoy] dddd') :
                                            recargasState.month ?
                                                moment().format('MMMM') :
                                                recargasState.year ?
                                                    moment().format('YYYY') :
                                                    'Total'
                                    }
                                </p>
                                <h4 style={{ fontFamily: 'Montserrat, sans-serif', fontStyle: 'normal', letterSpacing: 0.2 }} className="text-lg font-bold text-navy-700 dark:text-white">
                                    {
                                        recargasState.today ?
                                            <AnimatedNumber
                                                component="text"
                                                initialValue={recargas?.today}
                                                value={recargas?.today}
                                                stepPrecision={0}
                                                style={{
                                                    transition: "0.8s ease-out",
                                                    transitionProperty: "background-color, color, opacity"
                                                }}
                                                duration={2000}
                                                formatValue={n => 'S/ ' + n.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                            /> :
                                            recargasState.month ?
                                                <AnimatedNumber
                                                    component="text"
                                                    initialValue={recargas?.month}
                                                    value={recargas?.month}
                                                    stepPrecision={0}
                                                    style={{
                                                        transition: "0.8s ease-out",
                                                        transitionProperty: "background-color, color, opacity"
                                                    }}
                                                    duration={2000}
                                                    formatValue={n => 'S/ ' + n.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                                /> :
                                                recargasState.year ?
                                                    <AnimatedNumber
                                                        component="text"
                                                        initialValue={recargas?.year}
                                                        value={recargas?.year}
                                                        stepPrecision={0}
                                                        style={{
                                                            transition: "0.8s ease-out",
                                                            transitionProperty: "background-color, color, opacity"
                                                        }}
                                                        duration={2000}
                                                        formatValue={n => 'S/ ' + n.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                                    />
                                                    :
                                                    <AnimatedNumber
                                                        component="text"
                                                        initialValue={recargas?.total}
                                                        value={recargas?.total}
                                                        stepPrecision={0}
                                                        style={{
                                                            transition: "0.8s ease-out",
                                                            transitionProperty: "background-color, color, opacity"
                                                        }}
                                                        duration={2000}
                                                        formatValue={n => 'S/ ' + n.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                                    />
                                    }
                                </h4>
                            </div>
                        </div>
                    </div>
                    <div className="w-full !z-5 relative flex flex-col rounded-[20px] bg-white bg-clip-border shadow-3xl shadow-shadow-500 dark:!bg-brand-900 text-brand-950 dark:text-white dark:shadow-brand-950">
                        <div className="relative z-5 w-full flex items-center justify-end top-0 py-4 px-4">
                            <div className="w-[calc(calc(100%-1.25rem))] text-2xl text-left font-semibold">Usuarios nuevos</div>
                            <Dropdown
                                closeOnChildren={true}
                                button={<div className="hover:bg-brand-100 active:bg-brand-200 dark:hover:bg-brand-800 dark:active:bg-brand-700 transition-colors ease-in-out duration-300 rounded-full p-2"><FaEllipsisVertical className="w-5 h-5 text-right cursor-pointer text-brand-900 dark:text-brand-100" /></div>}
                                children={
                                    <div className="flex w-32 flex-col justify-start bg-white bg-cover bg-no-repeat shadow-lg shadow-brand-300 dark:!bg-brand-950 dark:text-white dark:shadow-black">
                                        <div className="flex flex-col">
                                            <div onClick={() => setNewUsersState({ ...recargasState, month: false, year: false, total: false, week: false, today: true })} className="text-sm font-medium transition duration-150 ease-out hover:ease-in cursor-pointer hover:bg-brand-100 dark:hover:bg-brand-800 px-3 py-2">
                                                Hoy
                                            </div>
                                            <div onClick={() => setNewUsersState({ ...recargasState, month: false, year: false, total: false, week: true, today: false })} className="text-sm font-medium transition duration-150 ease-out hover:ease-in cursor-pointer hover:bg-brand-100 dark:hover:bg-brand-800 px-3 py-2">
                                                Esta semana
                                            </div>
                                            <div onClick={() => setNewUsersState({ ...recargasState, month: true, year: false, total: false, week: false, today: false })} className="text-sm font-medium transition duration-150 ease-out hover:ease-in cursor-pointer hover:bg-brand-100 dark:hover:bg-brand-800 px-3 py-2">
                                                Este mes
                                            </div>
                                            <div onClick={() => setNewUsersState({ ...recargasState, month: false, year: true, total: false, week: false, today: false })} className="text-sm font-medium transition duration-150 ease-out hover:ease-in cursor-pointer hover:bg-brand-100 dark:hover:bg-brand-800 px-3 py-2">
                                                Este año
                                            </div>
                                            <div onClick={() => setNewUsersState({ ...recargasState, month: false, year: false, total: true, week: false, today: false })} className="text-sm font-medium transition duration-150 ease-out hover:ease-in cursor-pointer hover:bg-brand-100 dark:hover:bg-brand-800 px-3 py-2">
                                                Total
                                            </div>
                                        </div>
                                    </div>
                                }
                                animation="origin-[65%_0%] md:origin-top-right transition-all duration-300 ease-in-out"
                                classNames={"!absolute right-4 top-9"}
                            />
                        </div>
                        <div className="flex items-center">
                            <div className="ml-[18px] flex h-[80px] w-auto flex-row items-center">
                                <div className="rounded-full p-3 bg-blue-100 dark:bg-blue-950">
                                    <span className="flex items-center text-blue-500 dark:text-blue-500">
                                        <FaUsers size={32} />
                                    </span>
                                </div>
                            </div>

                            <div className="ml-4 flex w-auto flex-col justify-center">
                                <p className={`font-dm text-sm font-medium text-brand-600 dark:text-brand-400`}>
                                    {
                                        newusersState.today ?
                                            moment().format('[Hoy] dddd') :
                                            newusersState.week ?
                                                'Esta semana' :
                                                newusersState.month ?
                                                    moment().format('MMMM') :
                                                    newusersState.year ?
                                                        moment().format('YYYY') :
                                                        'Total'
                                    }
                                </p>
                                <h4 style={{ fontFamily: 'Montserrat, sans-serif', fontStyle: 'normal', letterSpacing: 0.2 }} className="text-lg font-bold text-navy-700 dark:text-white">
                                    {
                                        newusersState.today ?
                                            <AnimatedNumber
                                                component="text"
                                                initialValue={newusers?.today}
                                                value={newusers?.today}
                                                stepPrecision={0}
                                                style={{
                                                    transition: "0.8s ease-out",
                                                    transitionProperty: "background-color, color, opacity"
                                                }}
                                                duration={2000}
                                                formatValue={n => n}
                                            /> :
                                            newusersState.week ?
                                                <AnimatedNumber
                                                    component="text"
                                                    initialValue={newusers?.week}
                                                    value={newusers?.week}
                                                    stepPrecision={0}
                                                    style={{
                                                        transition: "0.8s ease-out",
                                                        transitionProperty: "background-color, color, opacity"
                                                    }}
                                                    duration={2000}
                                                    formatValue={n => n}
                                                />
                                                :
                                                newusersState.month ?
                                                    <AnimatedNumber
                                                        component="text"
                                                        initialValue={newusers?.month}
                                                        value={newusers?.month}
                                                        stepPrecision={0}
                                                        style={{
                                                            transition: "0.8s ease-out",
                                                            transitionProperty: "background-color, color, opacity"
                                                        }}
                                                        duration={2000}
                                                        formatValue={n => n}
                                                    />
                                                    :
                                                    newusersState.year ?
                                                        <AnimatedNumber
                                                            component="text"
                                                            initialValue={newusers?.year}
                                                            value={newusers?.year}
                                                            stepPrecision={0}
                                                            style={{
                                                                transition: "0.8s ease-out",
                                                                transitionProperty: "background-color, color, opacity"
                                                            }}
                                                            duration={2000}
                                                            formatValue={n => n}
                                                        />
                                                        :
                                                        <AnimatedNumber
                                                            component="text"
                                                            initialValue={newusers?.total}
                                                            value={newusers?.total}
                                                            stepPrecision={0}
                                                            style={{
                                                                transition: "0.8s ease-out",
                                                                transitionProperty: "background-color, color, opacity"
                                                            }}
                                                            duration={2000}
                                                            formatValue={n => n}
                                                        />

                                    }
                                </h4>
                            </div>
                        </div>
                    </div>
                    <div className="w-full md:col-span-2 lg:col-span-2 !z-5 relative rounded-[20px] bg-white bg-clip-border shadow-3xl shadow-shadow-500 dark:!bg-brand-900 text-brand-950 dark:text-white dark:shadow-brand-950">
                        <div className="relative z-5 w-full flex items-center justify-end top-0 py-4 px-4">
                            <div className="w-[calc(calc(100%-1.25rem))] text-2xl text-left font-semibold">
                                Top 5 Streaming {ventasTop5State.today ?
                                    moment().format('[hoy] dddd') :
                                    ventasTop5State.month ?
                                        moment().format('[de] MMMM') :
                                        ventasTop5State.year ?
                                            moment().format('[de] YYYY') :
                                            'Totales'}</div>
                            <Dropdown
                                closeOnChildren={true}
                                button={<div className="hover:bg-brand-100 active:bg-brand-200 dark:hover:bg-brand-800 dark:active:bg-brand-700 transition-colors ease-in-out duration-300 rounded-full p-2"><FaEllipsisVertical className="w-5 h-5 text-right cursor-pointer text-brand-900 dark:text-brand-100" /></div>}
                                children={
                                    <div className="flex w-32 flex-col justify-start bg-white bg-cover bg-no-repeat shadow-lg shadow-brand-300 dark:!bg-brand-950 dark:text-white dark:shadow-black">
                                        <div className="flex flex-col">
                                            <div onClick={() => setVentasTop5State({ ...ventasTop5State, month: false, year: false, total: false, today: true })} className="text-sm font-medium transition duration-150 ease-out hover:ease-in cursor-pointer hover:bg-brand-100 dark:hover:bg-brand-800 px-3 py-2">
                                                Hoy
                                            </div>
                                            <div onClick={() => setVentasTop5State({ ...ventasTop5State, month: true, year: false, total: false, today: false })} className="text-sm font-medium transition duration-150 ease-out hover:ease-in cursor-pointer hover:bg-brand-100 dark:hover:bg-brand-800 px-3 py-2">
                                                Este mes
                                            </div>
                                            <div onClick={() => setVentasTop5State({ ...ventasTop5State, month: false, year: true, total: false, today: false })} className="text-sm font-medium transition duration-150 ease-out hover:ease-in cursor-pointer hover:bg-brand-100 dark:hover:bg-brand-800 px-3 py-2">
                                                Este año
                                            </div>
                                            <div onClick={() => setVentasTop5State({ ...ventasTop5State, month: false, year: false, total: true, today: false })} className="text-sm font-medium transition duration-150 ease-out hover:ease-in cursor-pointer hover:bg-brand-100 dark:hover:bg-brand-800 px-3 py-2">
                                                Total
                                            </div>
                                            <div className="text-sm font-medium transition duration-150 ease-out hover:ease-in cursor-pointer hover:bg-brand-100 dark:hover:bg-brand-800 px-3 py-2">
                                                Más detalles
                                            </div>
                                        </div>
                                    </div>
                                }
                                animation="origin-[65%_0%] md:origin-top-right transition-all duration-300 ease-in-out"
                                classNames={"!absolute right-4 top-9"}
                            />
                        </div>
                        <div className="h-[320px] w-full p-3 !text-brand-950 dark:!text-brand-950">
                            <Chart options={
                                {
                                    chart: {
                                        type: 'bar',
                                        background: 'transparent',
                                        foreColor: theme === 'light' ? '#a3aed0' : '#adb5bd',
                                    },
                                    grid: {
                                        show: false
                                    },
                                    xaxis: {
                                        categories: ventasTop5State.today ?
                                            ventas?.top5Streaming?.today.map(item => item.subcategory) :
                                            ventasTop5State.year ?
                                                ventas?.top5Streaming?.year.map(item => item.subcategory) :
                                                ventasTop5State.month ?
                                                    ventas?.top5Streaming?.month.map(item => item.subcategory) :
                                                    ventas?.top5Streaming?.total.map(item => item.subcategory),
                                        axisBorder: {
                                            show: true,
                                            color: theme === 'light' ? '#e9ecef' : '#252f40',
                                        },
                                        axisTicks: {
                                            show: true,
                                            color: theme === 'light' ? '#e9ecef' : '#252f40',
                                        }
                                    },
                                    yaxis: {
                                        axisBorder: {
                                            show: false // Ocultar la línea del eje Y
                                        },
                                        axisTicks: {
                                            show: false // Ocultar las marcas del eje Y
                                        }
                                    },
                                    plotOptions: {
                                        bar: {
                                            horizontal: true, distributed: true, borderRadiusApplication: "end",
                                        }
                                    },
                                    colors: ventasTop5State.today ?
                                        ventas?.top5Streaming?.today.map(item => item.themecolor) :
                                        ventasTop5State.year ?
                                            ventas?.top5Streaming?.year.map(item => item.themecolor) :
                                            ventasTop5State.month ?
                                                ventas?.top5Streaming?.month.map(item => item.themecolor) :
                                                ventas?.top5Streaming?.total.map(item => item.themecolor),
                                    fill: {
                                        colors: ventasTop5State.today ?
                                            ventas?.top5Streaming?.today.map(item => item.themecolor) :
                                            ventasTop5State.year ?
                                                ventas?.top5Streaming?.year.map(item => item.themecolor) :
                                                ventasTop5State.month ?
                                                    ventas?.top5Streaming?.month.map(item => item.themecolor) :
                                                    ventas?.top5Streaming?.total.map(item => item.themecolor)
                                    },
                                    dataLabels: {
                                        enabled: true,
                                        textAnchor: 'middle',
                                        style: {
                                            colors: ['#fff'],
                                            fontSize: 14,
                                        },
                                        formatter: function (val, opt) {
                                            return currencyFormat(val)
                                        },
                                        offsetX: 0,
                                        dropShadow: {
                                            enabled: false
                                        }
                                    },
                                    title: {
                                        text: ''
                                    }
                                }
                            } series={[
                                {
                                    name: `Ventas ${ventasTop5State.today ?
                                        moment().format('[hoy] dddd') :
                                        ventasTop5State.month ?
                                            moment().format('[de] MMMM') :
                                            ventasTop5State.year ?
                                                moment().format('[de] YYYY') :
                                                'Totales'}`,
                                    data: ventasTop5State.today ?
                                        ventas?.top5Streaming?.today.map(item => item.totalToday) :
                                        ventasTop5State.year ?
                                            ventas?.top5Streaming?.year.map(item => item.totalCurrentYear) :
                                            ventasTop5State.month ?
                                                ventas?.top5Streaming?.month.map(item => item.totalCurrentMonth) :
                                                ventas?.top5Streaming?.total.map(item => item.total)
                                }
                            ]} type="bar" width="100%" height="100%" />
                        </div>
                    </div>
                    <div className="w-full md:col-span-2 lg:col-span-2 !z-5 relative rounded-[20px] bg-white bg-clip-border shadow-3xl shadow-shadow-500 dark:!bg-brand-900 text-brand-950 dark:text-white dark:shadow-brand-950">
                        <div className="relative z-5 w-full flex items-center justify-end top-0 py-4 px-4">
                            <div className="w-[calc(calc(100%-1.25rem))] text-2xl text-left font-semibold">
                                Top 5 Tarjetas de regalo {ventasGcTop5State.today ?
                                    moment().format('[hoy] dddd') :
                                    ventasGcTop5State.month ?
                                        moment().format('[de] MMMM') :
                                        ventasGcTop5State.year ?
                                            moment().format('[de] YYYY') :
                                            'Totales'}</div>
                            <Dropdown
                                closeOnChildren={true}
                                button={<div className="hover:bg-brand-100 active:bg-brand-200 dark:hover:bg-brand-800 dark:active:bg-brand-700 transition-colors ease-in-out duration-300 rounded-full p-2"><FaEllipsisVertical className="w-5 h-5 text-right cursor-pointer text-brand-900 dark:text-brand-100" /></div>}
                                children={
                                    <div className="flex w-32 flex-col justify-start bg-white bg-cover bg-no-repeat shadow-lg shadow-brand-300 dark:!bg-brand-950 dark:text-white dark:shadow-black">
                                        <div className="flex flex-col">
                                            <div onClick={() => setVentasGcTop5State({ ...ventasGcTop5State, month: false, year: false, total: false, today: true })} className="text-sm font-medium transition duration-150 ease-out hover:ease-in cursor-pointer hover:bg-brand-100 dark:hover:bg-brand-800 px-3 py-2">
                                                Hoy
                                            </div>
                                            <div onClick={() => setVentasGcTop5State({ ...ventasGcTop5State, month: true, year: false, total: false, today: false })} className="text-sm font-medium transition duration-150 ease-out hover:ease-in cursor-pointer hover:bg-brand-100 dark:hover:bg-brand-800 px-3 py-2">
                                                Este mes
                                            </div>
                                            <div onClick={() => setVentasGcTop5State({ ...ventasGcTop5State, month: false, year: true, total: false, today: false })} className="text-sm font-medium transition duration-150 ease-out hover:ease-in cursor-pointer hover:bg-brand-100 dark:hover:bg-brand-800 px-3 py-2">
                                                Este año
                                            </div>
                                            <div onClick={() => setVentasGcTop5State({ ...ventasGcTop5State, month: false, year: false, total: true, today: false })} className="text-sm font-medium transition duration-150 ease-out hover:ease-in cursor-pointer hover:bg-brand-100 dark:hover:bg-brand-800 px-3 py-2">
                                                Total
                                            </div>
                                            <div className="text-sm font-medium transition duration-150 ease-out hover:ease-in cursor-pointer hover:bg-brand-100 dark:hover:bg-brand-800 px-3 py-2">
                                                Más detalles
                                            </div>
                                        </div>
                                    </div>
                                }
                                animation="origin-[65%_0%] md:origin-top-right transition-all duration-300 ease-in-out"
                                classNames={"!absolute right-4 top-9"}
                            />
                        </div>
                        <div className="h-[320px] w-full p-3 !text-brand-950 dark:!text-brand-950">
                            <Chart options={
                                {
                                    chart: {
                                        type: 'bar',
                                        background: 'transparent',
                                        foreColor: theme === 'light' ? '#a3aed0' : '#adb5bd',
                                    },
                                    grid: {
                                        show: false
                                    },
                                    xaxis: {
                                        categories: ventasGcTop5State.today ?
                                            ventas?.top5GiftCard?.today.map(item => item.subcategory) :
                                            ventasGcTop5State.year ?
                                                ventas?.top5GiftCard?.year.map(item => item.subcategory) :
                                                ventasGcTop5State.month ?
                                                    ventas?.top5GiftCard?.month.map(item => item.subcategory) :
                                                    ventas?.top5GiftCard?.total.map(item => item.subcategory),
                                        axisBorder: {
                                            show: true,
                                            color: theme === 'light' ? '#e9ecef' : '#252f40',
                                        },
                                        axisTicks: {
                                            show: true,
                                            color: theme === 'light' ? '#e9ecef' : '#252f40',
                                        }
                                    },
                                    yaxis: {
                                        axisBorder: {
                                            show: false // Ocultar la línea del eje Y
                                        },
                                        axisTicks: {
                                            show: false // Ocultar las marcas del eje Y
                                        }
                                    },
                                    plotOptions: {
                                        bar: {
                                            horizontal: true, distributed: true, borderRadiusApplication: "end",
                                        }
                                    },
                                    colors: ventasGcTop5State.today ?
                                        ventas?.top5GiftCard?.today.map(item => item.themecolor) :
                                        ventasGcTop5State.year ?
                                            ventas?.top5GiftCard?.year.map(item => item.themecolor) :
                                            ventasGcTop5State.month ?
                                                ventas?.top5GiftCard?.month.map(item => item.themecolor) :
                                                ventas?.top5GiftCard?.total.map(item => item.themecolor),
                                    fill: {
                                        colors: ventasGcTop5State.today ?
                                            ventas?.top5GiftCard?.today.map(item => item.themecolor) :
                                            ventasGcTop5State.year ?
                                                ventas?.top5GiftCard?.year.map(item => item.themecolor) :
                                                ventasGcTop5State.month ?
                                                    ventas?.top5GiftCard?.month.map(item => item.themecolor) :
                                                    ventas?.top5GiftCard?.total.map(item => item.themecolor)
                                    },
                                    dataLabels: {
                                        enabled: true,
                                        textAnchor: 'middle',
                                        style: {
                                            colors: ['#fff'],
                                            fontSize: 14,
                                        },
                                        formatter: function (val, opt) {
                                            return currencyFormat(val)
                                        },
                                        offsetX: 0,
                                        dropShadow: {
                                            enabled: false
                                        }
                                    },
                                    title: {
                                        text: ''
                                    }
                                }
                            } series={[
                                {
                                    name: `Ventas ${ventasGcTop5State.today ?
                                        moment().format('[hoy] dddd') :
                                        ventasGcTop5State.month ?
                                            moment().format('[de] MMMM') :
                                            ventasGcTop5State.year ?
                                                moment().format('[de] YYYY') :
                                                'Totales'}`,
                                    data: ventasGcTop5State.today ?
                                        ventas?.top5GiftCard?.today.map(item => item.totalToday) :
                                        ventasGcTop5State.year ?
                                            ventas?.top5GiftCard?.year.map(item => item.totalCurrentYear) :
                                            ventasGcTop5State.month ?
                                                ventas?.top5GiftCard?.month.map(item => item.totalCurrentMonth) :
                                                ventas?.top5GiftCard?.total.map(item => item.total)
                                }
                            ]} type="bar" width="100%" height="100%" />
                        </div>
                    </div>
                    <div className="w-full md:col-span-2 lg:col-span-2 !z-5 relative rounded-[20px] bg-white bg-clip-border shadow-3xl shadow-shadow-500 dark:!bg-brand-900 text-brand-950 dark:text-white dark:shadow-brand-950">
                        <div className="relative z-5 w-full flex items-center justify-end top-0 py-4 px-4">
                            <div className="w-[calc(calc(100%-1.25rem))] text-2xl text-left font-semibold">
                                Top 5 Servicios SMM {ventasSocialTop5State.today ?
                                    moment().format('[hoy] dddd') :
                                    ventasSocialTop5State.month ?
                                        moment().format('[de] MMMM') :
                                        ventasSocialTop5State.year ?
                                            moment().format('[de] YYYY') :
                                            'Totales'}</div>
                            <Dropdown
                                closeOnChildren={true}
                                button={<div className="hover:bg-brand-100 active:bg-brand-200 dark:hover:bg-brand-800 dark:active:bg-brand-700 transition-colors ease-in-out duration-300 rounded-full p-2"><FaEllipsisVertical className="w-5 h-5 text-right cursor-pointer text-brand-900 dark:text-brand-100" /></div>}
                                children={
                                    <div className="flex w-32 flex-col justify-start bg-white bg-cover bg-no-repeat shadow-lg shadow-brand-300 dark:!bg-brand-950 dark:text-white dark:shadow-black">
                                        <div className="flex flex-col">
                                            <div onClick={() => setVentasSocialTop5State({ ...ventasSocialTop5State, month: false, year: false, total: false, today: true })} className="text-sm font-medium transition duration-150 ease-out hover:ease-in cursor-pointer hover:bg-brand-100 dark:hover:bg-brand-800 px-3 py-2">
                                                Hoy
                                            </div>
                                            <div onClick={() => setVentasSocialTop5State({ ...ventasSocialTop5State, month: true, year: false, total: false, today: false })} className="text-sm font-medium transition duration-150 ease-out hover:ease-in cursor-pointer hover:bg-brand-100 dark:hover:bg-brand-800 px-3 py-2">
                                                Este mes
                                            </div>
                                            <div onClick={() => setVentasSocialTop5State({ ...ventasSocialTop5State, month: false, year: true, total: false, today: false })} className="text-sm font-medium transition duration-150 ease-out hover:ease-in cursor-pointer hover:bg-brand-100 dark:hover:bg-brand-800 px-3 py-2">
                                                Este año
                                            </div>
                                            <div onClick={() => setVentasSocialTop5State({ ...ventasSocialTop5State, month: false, year: false, total: true, today: false })} className="text-sm font-medium transition duration-150 ease-out hover:ease-in cursor-pointer hover:bg-brand-100 dark:hover:bg-brand-800 px-3 py-2">
                                                Total
                                            </div>
                                            <div className="text-sm font-medium transition duration-150 ease-out hover:ease-in cursor-pointer hover:bg-brand-100 dark:hover:bg-brand-800 px-3 py-2">
                                                Más detalles
                                            </div>
                                        </div>
                                    </div>
                                }
                                animation="origin-[65%_0%] md:origin-top-right transition-all duration-300 ease-in-out"
                                classNames={"!absolute right-4 top-9"}
                            />
                        </div>
                        <div className="h-[320px] w-full p-3 !text-brand-950 dark:!text-brand-950">
                            <Chart options={
                                {
                                    chart: {
                                        type: 'bar',
                                        background: 'transparent',
                                        foreColor: theme === 'light' ? '#a3aed0' : '#adb5bd',
                                    },
                                    grid: {
                                        show: false
                                    },
                                    xaxis: {
                                        categories: ventasSocialTop5State.today ?
                                            ventas?.top5Social?.today.map(item => item.subcategory) :
                                            ventasSocialTop5State.year ?
                                                ventas?.top5Social?.year.map(item => item.subcategory) :
                                                ventasSocialTop5State.month ?
                                                    ventas?.top5Social?.month.map(item => item.subcategory) :
                                                    ventas?.top5Social?.total.map(item => item.subcategory),
                                        axisBorder: {
                                            show: true,
                                            color: theme === 'light' ? '#e9ecef' : '#252f40',
                                        },
                                        axisTicks: {
                                            show: true,
                                            color: theme === 'light' ? '#e9ecef' : '#252f40',
                                        }
                                    },
                                    yaxis: {
                                        axisBorder: {
                                            show: false // Ocultar la línea del eje Y
                                        },
                                        axisTicks: {
                                            show: false // Ocultar las marcas del eje Y
                                        }
                                    },
                                    plotOptions: {
                                        bar: {
                                            horizontal: true, distributed: true, borderRadiusApplication: "end",
                                        }
                                    },
                                    colors: ventasSocialTop5State.today ?
                                        ventas?.top5Social?.today.map(item => item.themecolor) :
                                        ventasSocialTop5State.year ?
                                            ventas?.top5Social?.year.map(item => item.themecolor) :
                                            ventasSocialTop5State.month ?
                                                ventas?.top5Social?.month.map(item => item.themecolor) :
                                                ventas?.top5Social?.total.map(item => item.themecolor),
                                    fill: {
                                        colors: ventasSocialTop5State.today ?
                                            ventas?.top5Social?.today.map(item => item.themecolor) :
                                            ventasSocialTop5State.year ?
                                                ventas?.top5Social?.year.map(item => item.themecolor) :
                                                ventasSocialTop5State.month ?
                                                    ventas?.top5Social?.month.map(item => item.themecolor) :
                                                    ventas?.top5Social?.total.map(item => item.themecolor)
                                    },
                                    dataLabels: {
                                        enabled: true,
                                        textAnchor: 'middle',
                                        style: {
                                            colors: ['#fff'],
                                            fontSize: 14,
                                        },
                                        formatter: function (val, opt) {
                                            return currencyFormat(val)
                                        },
                                        offsetX: 0,
                                        dropShadow: {
                                            enabled: false
                                        }
                                    },
                                    title: {
                                        text: ''
                                    }
                                }
                            } series={[
                                {
                                    name: `Ventas ${ventasSocialTop5State.today ?
                                        moment().format('[hoy] dddd') :
                                        ventasSocialTop5State.month ?
                                            moment().format('[de] MMMM') :
                                            ventasSocialTop5State.year ?
                                                moment().format('[de] YYYY') :
                                                'Totales'}`,
                                    data: ventasSocialTop5State.today ?
                                        ventas?.top5Social?.today.map(item => item.totalToday) :
                                        ventasSocialTop5State.year ?
                                            ventas?.top5Social?.year.map(item => item.totalCurrentYear) :
                                            ventasSocialTop5State.month ?
                                                ventas?.top5Social?.month.map(item => item.totalCurrentMonth) :
                                                ventas?.top5Social?.total.map(item => item.total)
                                }
                            ]} type="bar" width="100%" height="100%" />
                        </div>
                    </div>
                    <div className="w-full md:col-span-2 lg:col-span-2 !z-5 relative pb-4 overflow-hidden rounded-[20px] bg-white bg-clip-border shadow-3xl shadow-shadow-500 dark:!bg-brand-900 text-brand-950 dark:text-white dark:shadow-brand-950">
                        <div className="relative z-5 w-full flex items-center justify-end top-0 py-4 px-4">
                            <div className="w-[calc(calc(100%))] text-2xl text-left font-semibold">
                                Últimas ventas
                            </div>
                        </div>
                        <div className="h-[320px] px-4 w-full !text-brand-950 dark:!text-brand-950 overflow-y-auto">
                            {
                                last10Ventas?.map((venta) => {
                                    var action = ""
                                    if (venta.action === 'New Streaming') {
                                        action = 'ah comprado un servicio de'
                                    } else if (venta.action === 'Renew Streaming') {
                                        action = 'ah renovado su servicio de'
                                    } else if (venta.action === 'New GiftCard') {
                                        action = 'ah comprado un servicio de'
                                    } else {
                                        action = 'ah comprado un SMM servicio de '
                                    }
                                    return (
                                        <div className="flex flex-wrap py-4 rounded-[20px] bg-white bg-clip-border shadow-lg shadow-shadow-500 dark:!bg-brand-900 text-brand-950 dark:text-white dark:shadow-brand-950 mb-5" key={venta._id}>
                                            <div className="flex items-center justify-center max-w-[60px] min-w-[60px] max-h-[60px] min-h-[60px]">
                                                {
                                                    venta.user?.photo === 'unknowphoto.webp' ?
                                                        <div style={{ background: `${generateBackgroundColor(venta.user?.name)}` }} className="uppercase relative max-w-[40px] min-w-[40px] max-h-[40px] min-h-[40px] rounded-full flex items-center justify-center text-white text-2xl font-normal z-10">
                                                            {venta.user?.name.substr(0, 1)}
                                                        </div>
                                                        :
                                                        <LazyLoadImage
                                                            className="max-w-[40px] min-w-[40px] max-h-[40px] min-h-[40px] rounded-full object-cover"
                                                            alt={'No image'}
                                                            effect="opacity"
                                                            src={`/images/photos/${venta.user?.photo}`}
                                                        />
                                                }
                                            </div>
                                            <div className="w-[calc(100%-60px)] md:w-[calc(100%-60px)] lg:w-[calc(35%-60px)] grid gap-1 self-center">
                                                <p className="text-sm truncate ...">{venta.user?.name}</p>
                                                <p className="text-sm text-brand-700 dark:text-brand-300 font-light truncate ...">{venta.user?.email}</p>
                                            </div>
                                            <div className="w-[calc(100%-100px)] md:w-[calc(100%-100px)] lg:w-[calc(65%-100px)] flex items-center">
                                                <p className="text-sm font-light text-left px-4">{action} {venta.smmservicio ? venta.smmservicio?.name : venta.subcategory && venta.subcategory?.name}</p>
                                            </div>
                                            <div className="w-[100px] flex items-center justify-center">
                                                <p className="text-sm px-2">{moment(venta.created_at).fromNow()}</p>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Wrapper>
    );
};

export default Dashboard;
