import { useState, useEffect, useRef } from "react";
import { IoAlarm, IoBan, IoEye, IoEyeOff, IoLockClosed, IoPerson, IoSave } from 'react-icons/io5';
import { MdAlternateEmail, MdEditOff, MdLockClock } from 'react-icons/md';
import { Button, IconButton, MenuItem, Tooltip, FormControl, Select } from "@mui/material";
import Spinner from "../../components/Spinner";
import moment from 'moment';
import { CalcularExpiracion, message, PermiteSoloNumeros, setUnixToTimeFormat } from "../../utils/Funciones.js";
import { FaShippingFast, FaUserCheck, FaUserClock } from "react-icons/fa";
import Swal from "sweetalert2";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useDispatch, useSelector } from "react-redux";
import { SET_TO_DEFAULT_RESPONSE, UPDATE_CUENTA_EXTERNAL, contador, update } from "../../store/features/slices/cuentaSlice";
import { bloquearVentasByCorreoId, getByCorreoId, rejectedOrLockVenta, SET_TO_DEFAULT_RESPONSE as SET_TO_DEFAULT_RESPONSE_VENTAS, suspendedById } from "../../store/features/slices/ventaSlice";
import { FaClipboard, FaLock, FaMobileScreen, FaRegUser, FaScreenpal, FaSquareCheck } from "react-icons/fa6";
import CopyToClipboard from "react-copy-to-clipboard";
import Socket, { authSocket, logoutSocket } from '../../utils/Socket';
import Switch from "../../components/switch";

const UpdateCuenta = ({
  SECRET_KEY,
  cuenta,
  category,
  subcategory,
  loadingcategory,
  HandleCloseEditCuenta
}) => {
  const dispatch = useDispatch();
  const [VerPerfiles, SetVerPerfiles] = useState(false)
  const [IsProfilesBlocked, SetIsProfilesBlocked] = useState(false)
  const [viewcurrentperfiles, SetViewcurrentperfiles] = useState(false)

  const ulRef = useRef(null);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (ulRef.current) {
      setHeight(ulRef.current.scrollHeight);
    }
  }, [viewcurrentperfiles]);

  const {
    isMutation,
  } = useSelector((state) => state.cuentaReducer);

  const {
    ventasByCorreoId,
    isMutation: isMutationVentas,
  } = useSelector((state) => state.ventaReducer);

  useEffect(() => {
    if (isMutationVentas.success) {
      message(isMutationVentas.message, 'success', 5)
      dispatch(SET_TO_DEFAULT_RESPONSE_VENTAS())
    } else {
      if (cuenta) {
        dispatch(getByCorreoId(cuenta._id))
      }
    }
  }, [dispatch, isMutationVentas.success]);


  const [state, setState] = useState({
    _id: '',
    correo: '',
    passwordcorreo: '',
    passwordcuenta: '',
    category: '',
    subcategory: '',
    expirationdate: '',
    observations: '',
    servicetype: '',
    profilesinfo: [],
    renovable: false,
    locked: false,
    currentrenovable: false
  });

  const [VerPasswords, SetVerPasswords] = useState(
    {
      passwordcorreo: false,
      passwordcuenta: false,
      passwordp1: false,
      passwordp2: false,
      passwordp3: false,
      passwordp4: false,
      passwordp5: false,
      passwordp6: false,
      passwordp7: false,
      passwordp8: false,
      passwordp9: false,
      passwordp10: false,
    })

  const handleInput = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const HandleChangeProfileInfo = (value, name, id) => {
    const NewState = state.profilesinfo.map((pro) => {
      if (pro._id === id) {
        return {
          ...pro,
          [name]: value
        }
      }
      return pro
    })
    setState({ ...state, profilesinfo: NewState })
  }

  const UpdateCuenta = (e) => {
    e.preventDefault();
    dispatch(update(state))
  };

  useEffect(() => {
    if (isMutation.updated) {
      if (isMutation.extra.affectSubCategory) {
        Socket.emit('uSubCategory', { newData: isMutation.extra.subcategory })
      }
      message(isMutation.message, 'success', 5)
      dispatch(contador())
      dispatch(SET_TO_DEFAULT_RESPONSE())
      HandleCloseEditCuenta()
    }
  }, [isMutation.updated]);

  useEffect(() => {
    if (isMutationVentas.updated) {
      message(isMutationVentas.message, 'success', 5)
      if (isMutationVentas.extra.affectSubCategory) {
        Socket.emit('uSubCategory', { newData: isMutationVentas.extra.subcategory })
      }
      if (!isMutationVentas.extra.suspend) {
        setState({ ...state, profilesinfo: isMutationVentas.extra.profilesinfo })
        dispatch(UPDATE_CUENTA_EXTERNAL({ cuenta: isMutationVentas.extra.cuenta, newcontadorprofilesexpired: isMutationVentas.extra.newcontadorcuenta }))
      }
      dispatch(SET_TO_DEFAULT_RESPONSE_VENTAS())
    }
  }, [isMutationVentas.updated]);

  useEffect(() => {
    if (!loadingcategory && cuenta) {
      const cuentaforedit = {
        _id: cuenta._id,
        correo: cuenta.correo,
        passwordcorreo: cuenta.passwordcorreo,
        passwordcuenta: cuenta.passwordcuenta,
        category: cuenta.category._id,
        subcategory: cuenta.subcategory._id,
        expirationdate: moment(cuenta.expirationdate).format("yyyy-MM-DDTHH:mm"),
        observations: cuenta.observations,
        created_at: cuenta.created_at,
        servicetype: cuenta.servicetype,
        edtadouso: cuenta.estadouso,
        profilesinfo: cuenta.profilesinfo,
        locked: cuenta.locked,
        last_update: cuenta.last_update,
        renovable: cuenta.renovable ? cuenta.renovable : false,
        currentrenovable: cuenta.renovable ? cuenta.renovable : false
      }

      subcategory.find((subcat) => {
        if (subcat._id === cuenta.subcategory._id) {
          SetIsProfilesBlocked(subcat.isprofileblocked)
        }
      })
      setState(cuentaforedit)
    }

  }, [cuenta]);

  const OnChangeLocked = () => {
    setState({ ...state, ['locked']: !state.locked })
  }

  const alertaConfirmar = (titulo, texto, icon, callback) => {
    Swal.fire({
      title: titulo,
      html: texto,
      icon: icon,
      showCancelButton: true,
      confirmButtonText: 'Si',
      denyButtonText: 'No',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#2C3E50',
      cancelButtonColor: '#CB4335'
    }).then((result) => {
      if (result.isConfirmed) {
        callback(result.isConfirmed)
      }
    })
  }

  const handleLockOrRejectedVenta = (id, actiontype, state, isExpired) => {

    if (actiontype === 'locked') {
      if (state === 'complete' && isExpired !== 'Caducado') {
        alertaConfirmar('Bloquear pedido - No expirado', 'Este pedido aún no ah expirado, deseas forzar el bloqueo ?', 'question', (res_alert) => {
          if (res_alert === true) {
            dispatch(rejectedOrLockVenta({ _id: id, actiontype: actiontype, message: '', force: true, from: 'cuentas' }));
          }
        })
      } else {
        alertaConfirmar('Bloquear pedido', 'Al bloquear este pedido el usuario ya no podrá renovarlo ni tener ningún acceso.', 'warning', (res_alert) => {
          if (res_alert === true) {
            dispatch(rejectedOrLockVenta({ _id: id, actiontype: actiontype, message: '', force: false, from: 'cuentas' }));
          }
        })
      }
    }
  }

  const handleSuspended = (venta) => {
    if (venta.suspended) {
      alertaConfirmar('REANUDAR PEDIDO', 'estás seguro que quieres reanudar este pedido? el usuario recibirá automaticamente las crendeciales actuales.', 'warning', (res_alert) => {
        if (res_alert === true) {
          dispatch(suspendedById({ _id: venta._id, actiontype: 'unsuspend', from: 'cuentas' }));
        }
      })
    } else {
      alertaConfirmar('SUSPENDER PEDIDO', 'estás seguro que quieres suspender este pedido? el usuario no recibirá las credenciales de este pedido.', 'warning', (res_alert) => {
        if (res_alert === true) {
          dispatch(suspendedById({ _id: venta._id, actiontype: 'suspend', from: 'cuentas' }));
        }
      })
    }
  }

  const HandleLockPerfilesVencidos = () => {
    dispatch(bloquearVentasByCorreoId({ correoid: state._id }))
  }

  const onCopy = (text) => {
    message(text, 'success', 2)
  }

  return (
    <form className="w-full" onSubmit={UpdateCuenta}>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 mb-5">
        <div className="w-full">
          <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Correo o usuario de la cuenta</label>
          <input autoFocus type="text" name="correo" className="custom-style-input"
            autoComplete="off"
            required
            value={state.correo}
            onChange={handleInput}
          />
        </div>

        <div className="w-full relative">

          <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Password del correo <span className="text-xs text-amber-600 dark:text-amber-400">(opcional)</span></label>
          <input name="passwordcorreo" className="custom-style-input"
            autoComplete="off"
            value={state.passwordcorreo}
            onChange={handleInput}
            type={VerPasswords.passwordcorreo ? 'text' : 'password'}
          />
          <div class="absolute inset-y-4 mt-7 right-0 pr-3 flex items-center text-sm leading-5">
            {<IconButton color='primary' onClick={() => SetVerPasswords({ ...VerPasswords, ['passwordcorreo']: !VerPasswords.passwordcorreo })}>
              {VerPasswords.passwordcorreo ? <IoEyeOff className="text-brand-900 dark:text-brand-200" /> : <IoEye className="text-brand-900 dark:text-brand-200" />}
            </IconButton>}
          </div>
        </div>

        <div className="w-full relative">
          <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Password de la cuenta</label>
          <input name="passwordcuenta" className="custom-style-input"
            autoComplete="off"
            required
            value={state.passwordcuenta}
            onChange={handleInput}
            type={VerPasswords.passwordcuenta ? 'text' : 'password'}
          />
          <div class="absolute inset-y-4 mt-7 right-0 pr-3 flex items-center text-sm leading-5">
            {<IconButton color='primary' onClick={() => SetVerPasswords({ ...VerPasswords, ['passwordcuenta']: !VerPasswords.passwordcuenta })}>
              {VerPasswords.passwordcuenta ? <IoEyeOff className="text-brand-900 dark:text-brand-200" /> : <IoEye className="text-brand-900 dark:text-brand-200" />}
            </IconButton>}
          </div>

        </div>

        <div className="w-full">

          <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Expiración de la cuenta</label>
          <input name="expirationdate" className="custom-style-input"
            autoComplete="off"
            required
            value={state.expirationdate}
            onChange={handleInput}
            type="datetime-local"
          />
        </div>

        <div className="w-full">
          <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Tipo de servicio</label>
          <Tooltip title={
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <MdEditOff size={18} style={{ marginRight: 5 }} />
              Tipo de servicio no se puede editar
            </div>}

            PopperProps={{
              sx: {
                "& .MuiTooltip-tooltip": {
                  fontSize: 12.5,
                }
              }
            }}
            arrow>
            <FormControl fullWidth>
              <Select
                required
                name="servicetype"
                className="flex items-center"
                variant="outlined"
                value={state.servicetype}
              >
                {
                  state.servicetype === 'completa' ?
                    <MenuItem value={'completa'} key={'completa'} className="flex items-center"><LazyLoadImage width={25} height={25} src={`/images/logos/completa.webp`} className="mr-2 rounded-full" />Cuenta completa</MenuItem>
                    :
                    <MenuItem value={'compartida'} key={'compartida'} className="flex items-center"><LazyLoadImage width={25} height={25} src={`/images/logos/pantalla.webp`} className="mr-2 rounded-full" />Cuenta compartida</MenuItem>
                }
              </Select>
            </FormControl>
          </Tooltip>
        </div>

        <div className="w-full">
          <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Categoría</label>
          {!loadingcategory ? category.length > 0 && <Tooltip title={
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <MdEditOff size={18} style={{ marginRight: 5 }} />
              Categoría no se puede editar
            </div>}

            PopperProps={{
              sx: {
                "& .MuiTooltip-tooltip": {
                  fontSize: 12.5,
                }
              }
            }}
            arrow>
            <FormControl fullWidth>
              <Select
                name="category"
                className="flex items-center"
                variant="outlined"
                value={state.category}
              >
                {category.map(cat => {
                  if (cat._id === state.category)
                    return <MenuItem value={cat._id} key={cat._id} className="flex items-center"><LazyLoadImage width={25} height={25} src={`/images/logos/${cat.sublogo}`} className="mr-2 rounded-full" />{cat.name}</MenuItem>
                })}
              </Select>
            </FormControl>
          </Tooltip> : <Spinner w={'35px'} h={'35px'} />}
        </div>

        <div className="w-full">
          <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Subcategoría</label>
          {!loadingcategory ? subcategory.length > 0 && <Tooltip title={
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <MdEditOff size={18} style={{ marginRight: 5 }} />
              Subcategoría no se puede editar
            </div>}

            PopperProps={{
              sx: {
                "& .MuiTooltip-tooltip": {
                  fontSize: 12.5,
                }
              }
            }}
            arrow>
            <FormControl fullWidth>
              <Select
                name="subcategory"
                className="flex items-center"
                variant="outlined"
                value={state.subcategory}
              >
                {
                  subcategory.map(subcat => {
                    if (subcat._id === state.subcategory)
                      return <MenuItem value={subcat._id} key={subcat._id} className="flex items-center"><LazyLoadImage width={25} height={25} src={`/images/logos/${subcat.logo}`} className="mr-2 rounded-full" />{subcat.name}</MenuItem>
                  })
                }
              </Select>
            </FormControl>
          </Tooltip> : <Spinner w={'35px'} h={'35px'} />}
        </div>

        <div className="w-full">
          <label className="text-brand-900 dark:text-brand-200 text-sm">Bloqueada ? <span className="font-semibold">{state.locked ? "Si" : "No"}</span></label>
          <div className="h-10 mt-3">
            <Switch
              value={state.locked}
              onChange={OnChangeLocked}
            />
          </div>
        </div>

        <div className="w-full">
          <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Observaciones <span className="text-xs text-amber-600 dark:text-amber-400">(opcional)</span></label>
          <textarea name="observations" className="custom-style-input"
            rows="3"
            autoComplete="off"
            value={state.observations}
            onChange={handleInput}
          />
        </div>
        <div className="w-full md:w-[calc(33.33%-10px)] md:col-span-3">
          {IsProfilesBlocked &&

            <div onClick={() => SetVerPerfiles(!VerPerfiles)}
              className="bg-indigo-500 hover:bg-indigo-600 p-4 w-full text-center cursor-pointer rounded-lg transition">
              {VerPerfiles ? 'OCULTAR' : 'VER'} PERFILES
            </div>
          }
        </div>

        {
          VerPerfiles &&

          <div className="w-full md:col-span-3">
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-5">

              {
                state.profilesinfo.map((pro, i) => {
                  var passwordp = `passwordp${i + 1}`
                  var categoryname = `name`
                  var password = `password`

                  return (
                    <div className={`relative ${pro.isused ? 'bg-gradient-to-r from-red-400 via-red-500 to-red-600' : 'bg-gradient-to-r from-green-400 via-green-500 to-green-600'} rounded-lg text-brand-900 shadow-md shadow-brand-500 dark:shadow-black p-4 cursor-pointer hover:scale-[101%] animation transition-all`}>
                      <div className="absolute text-center text-white font-bold rounded-full p-2 left-1 top-1 bg-white bg-opacity-30 text-3xl w-14 h-14 flex items-center justify-center">
                        {i + 1}
                      </div>
                      <IoPerson size={64} className="text-brand-100 rounded-full m-auto" />
                      <div className="h-12 text-center font-semibold text-brand-100 text-xs flex items-center justify-center">
                        {pro.byuserid ? pro.byuserid.email : ''}
                      </div>

                      <div className="m-auto mb-2">
                        <label for="first_name" class="block mb-2 text-sm font-medium text-brand-100 font-semibold">Nombre</label>
                        <input key={i} name={categoryname} className="bg-brand-950 bg-opacity-20 p-[15px] text-brand-100 text-base rounded-lg focus:ring-blue-600 block w-full placeholder-brand-300 dark:placeholder-brand-600 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                          placeholder="Nombre"
                          autoComplete="off"
                          required
                          onChange={(e) => HandleChangeProfileInfo(e.target.value, e.target.name, pro._id)}
                          value={pro[categoryname]}
                          type="text"
                        />
                      </div>
                      <div className="m-auto relative">
                        <label for="first_name" class="block mb-2 text-sm font-medium text-brand-100 font-semibold">PIN</label>
                        <input key={i} name={password} className="bg-brand-950 bg-opacity-20 p-[15px] text-brand-100 text-base rounded-lg focus:ring-blue-600 block w-full placeholder-brand-300 dark:placeholder-brand-600 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                          placeholder="PIN"
                          autoComplete="off"
                          required
                          onKeyPress={PermiteSoloNumeros}
                          onChange={(e) => HandleChangeProfileInfo(e.target.value, e.target.name, pro._id)}
                          value={pro[password]}
                          type={VerPasswords[passwordp] ? 'text' : 'password'}
                          maxLength={5}
                        />
                        <div class="absolute inset-y-4 mt-7 right-0 pr-3 flex items-center text-sm leading-5">
                          {<IconButton color='primary' onClick={(e) => SetVerPasswords({ ...VerPasswords, [passwordp]: !VerPasswords[passwordp] })}>
                            {VerPasswords[passwordp] ? <IoEyeOff className="text-brand-300" /> : <IoEye className="text-brand-300" />}
                          </IconButton>}
                        </div>
                      </div>
                    </div>

                  )
                })
              }
            </div>
          </div>
        }
      </div>
      <div className="flex m-auto w-full">
        <Button
          type="submit"
          disabled={isMutation.loading ? true : false}
          style={{ width: '100%', height: 65, marginLeft: 'auto', marginRight: 'auto' }}
          size='large' variant="contained"
        >
          {isMutation.loading ? <Spinner /> : <IoSave size={24} className="mr-2" />}
          {isMutation.loading ? 'Actualizando...' : 'Actualizar cuenta'}
        </Button>
      </div>
      <div className="w-full mt-5">
        {
          ventasByCorreoId.length > 0 &&
          <>
            <Button
              type="button"
              disabled={isMutationVentas.loading ? true : false}
              onClick={() => SetViewcurrentperfiles(!viewcurrentperfiles)}
              style={{ width: '100%', height: 65, marginLeft: 'auto', marginRight: 'auto' }}
              size='large' variant="contained" color="success"
            >
              {viewcurrentperfiles ? 'OCULTAR' : 'VER'} USUARIOS QUE USAN ESTA CUENTA
            </Button>

            <ul ref={ulRef}
              className={`w-full p-2 overflow-hidden transition-all duration-700 block'}`}
              style={{ height: viewcurrentperfiles ? `${height}px` : '0px' }}>
              <div className="flex w-full inline-flex mb-12">
                <div>
                  <Tooltip title="Bloquear pedidos vencidos" arrow={true}>
                    <IconButton disabled={isMutationVentas.uloading ? true : false} onClick={HandleLockPerfilesVencidos} color="primary" className="bg-brand-100 dark:bg-brand-800">
                      <MdLockClock size={36} className="text-red-600" />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
              {
                ventasByCorreoId.map((venta) => {
                  if ((cuenta._id === venta.correo._id) &&
                    venta.state === 'complete' &&
                    venta.ventatype === 'Streaming') {
                    return (
                      <div className={`p-4 relative w-full shadow-xl shadow-neutral-200 dark:shadow-none cursor-pointer md:w-12/12 bg-white dark:bg-brand-900 text-brand-900 dark:text-brand-200 flex flex-wrap mb-14 rounded-lg animation`} key={venta._id}>
                        <label style={{ filter: venta.suspended ? 'grayscale(100%)' : 'none' }} className="absolute -top-8 left-0 p-2 flex items-center text-brand-800 dark:text-brand-200 text-base rounded-tl-lg rounded-tr-lg bg-white dark:bg-brand-900">
                          <span className="text-[12px]">{venta.ventaId}</span>
                        </label>
                        <div style={{ filter: venta.suspended ? 'grayscale(100%)' : 'none' }} className="relative grid grid-wrap w-full md:w-full lg:w-[calc(41.666667%-80px)]">
                          <div className="flex inline-flex items-center">
                            <LazyLoadImage
                              className="object-cover w-14 h-14 z-10 rounded-full flex inline-flex"
                              alt={`No image ${venta.subcategory.name}`}
                              effect="opacity"
                              src={`/images/logos/${venta.subcategory.logo}`}
                            />
                            <div className="grid gap-1 self-center">
                              <div className="ml-2 text-sm text-brand-900 dark:text-brand-200 font-bold">{venta.subcategory.name}</div>
                              <div className="ml-2 text-xs truncate ...">{setUnixToTimeFormat('created_at', venta.created_at)}</div>
                              <div className="ml-2 flex items-center text-xs truncate ..."><MdAlternateEmail size={16} className='mr-[5px]' />  {venta.user?.email}
                                <span className="ml-3">
                                  <Tooltip title="copiar" arrow={true}>
                                    <div>
                                      <CopyToClipboard text={venta.user.email} onCopy={() => onCopy('correo copiado')}>
                                        <FaClipboard size={20} className="text-brand-900 dark:text-brand-200 cursor-pointer w-[35px]" />
                                      </CopyToClipboard>
                                    </div>
                                  </Tooltip>
                                </span>
                              </div>
                              <div className="ml-2 flex items-center text-xs truncate ..."><FaRegUser size={16} className='mr-[5px]' /> {venta.user?.name}</div>
                            </div>
                          </div>
                        </div>

                        <div style={{ filter: venta.suspended ? 'grayscale(100%)' : 'none' }} className="relative md:flex grid grid-cols-1 md:grid-cols-1 gap-3 items-center w-full md:w-full lg:w-[58.333333%] mt-5 md:mt-4 lg:mt-0">

                          <div className="md:w-4/12 w-full grid">
                            <div className="flex items-center justify-center font-bold">Expiración</div>
                            <div className="flex items-center justify-center">
                              <div className="flex inline-flex items-center">
                                {venta.expirationdate === null ?
                                  <div class="text-center text-yellow-950 dark:text-yellow-50 bg-gradient-to-r from-yellow-400 dark:from-yellow-500 rounded-l-lg flex items-center p-2"><IoAlarm size={24} className="mr-2" />Expiración pendiente</div>
                                  :
                                  <span>{(CalcularExpiracion(venta.expirationdate) === "Caducado" && venta.state !== "locked") ?
                                    <div class="text-center text-red-950 dark:text-red-50 bg-gradient-to-r from-red-400 dark:from-red-500 rounded-l-lg flex items-center p-2"><IoAlarm size={24} className="mr-2" />{CalcularExpiracion(venta.expirationdate)}</div>
                                    :
                                    <div>{(CalcularExpiracion(venta.expirationdate).substring(0, 2).trim().length === 1 && Number(CalcularExpiracion(venta.expirationdate).substring(0, 1)) >= 0 &&
                                      Number(CalcularExpiracion(venta.expirationdate).substring(0, 1)) < 3) ?
                                      <div class="text-center text-orange-950 dark:text-orange-50 bg-gradient-to-r from-orange-400 dark:from-orange-500 rounded-l-lg flex items-center p-2"><IoAlarm size={24} className="mr-2" />{CalcularExpiracion(venta.expirationdate)}</div>
                                      :
                                      (venta.state === 'rejected' || venta.state === 'locked') ?
                                        <div class="text-center text-red-950 dark:text-red-50 bg-gradient-to-r from-red-400 dark:from-red-500 rounded-l-lg flex items-center p-2"><IoAlarm size={24} className="mr-2" />{CalcularExpiracion(venta.expirationdate)}</div>
                                        :
                                        <div class="text-center text-green-950 dark:text-green-50 bg-gradient-to-r from-green-400 dark:from-green-500 rounded-l-lg flex items-center p-2"><IoAlarm size={24} className="mr-2" />{CalcularExpiracion(venta.expirationdate)}</div>
                                    }</div>
                                  }</span>
                                }
                              </div>
                            </div>
                          </div>

                          <div className="md:w-4/12 w-full grid">
                            <div className="flex items-center justify-center font-bold">Tipo servicio</div>
                            <div className="flex items-center justify-center">
                              <div className="flex inline-flex items-center">
                                {venta.type === '1 pantalla' ?
                                  <div class="text-center text-indigo-950 dark:text-indigo-50 bg-gradient-to-r from-indigo-400 dark:from-indigo-500 rounded-l-lg flex items-center p-2"><FaMobileScreen size={24} className="mr-2" />{venta.type}</div>
                                  :
                                  <div class="text-center text-amber-950 dark:text-amber-50 bg-gradient-to-r from-amber-400 dark:from-amber-500 rounded-l-lg flex items-center p-2"><FaScreenpal size={24} className="mr-2" />{venta.type}</div>
                                }
                              </div>
                            </div>
                          </div>

                          <div className="md:w-4/12 w-full grid">
                            <div className="flex items-center justify-center font-bold">Estado</div>
                            <div className="flex items-center justify-center">
                              {
                                venta.suspended ?
                                  <div class="text-center text-orange-950 dark:text-orange-50 bg-gradient-to-r from-orange-400 dark:from-orange-500 rounded-l-lg flex items-center p-2"><FaUserClock size={24} className="mr-2" />Suspendido</div>
                                  :
                                  <>
                                    {venta.state === "complete" && <div class="text-center text-green-950 dark:text-green-50 bg-gradient-to-r from-green-400 dark:from-green-500 rounded-l-lg flex items-center p-2"><FaSquareCheck size={24} className="mr-2" />Completado</div>}
                                    {venta.state === "pending" && <div class="text-center text-yellow-950 dark:text-yellow-50 bg-gradient-to-r from-yellow-400 dark:from-yellow-500 rounded-l-lg flex items-center p-2"><FaShippingFast size={24} className="mr-2" />Pendiente</div>}
                                    {venta.state === "rejected" && <div class="text-center text-red-950 dark:text-red-50 bg-gradient-to-r from-red-400 dark:from-red-500 rounded-l-lg flex items-center p-2"><IoBan size={24} className="mr-2" />Rechazado</div>}
                                    {venta.state === "locked" && <div class="text-center text-red-950 dark:text-red-50 bg-gradient-to-r from-red-400 dark:from-red-500 rounded-l-lg flex items-center p-2"><IoLockClosed size={24} className="mr-2" />Bloqueado</div>}
                                  </>
                              }
                            </div>

                            {venta.observations !== "" &&
                              <span className='text-xs text-center'>
                                {venta.observations}
                              </span>
                            }

                          </div>

                        </div>
                        <div className="flex items-center gap-2 justify-end w-full md:w-full lg:md:w-[80px] mt-3 md:mt-3 lg:mt-0">
                          {
                            venta.suspended ?
                              <Tooltip title="reanudar" arrow={true}>
                                <Button onClick={() => handleSuspended(venta)} disabled={(venta.state !== "complete" || isMutationVentas.uloading) ? true : false} variant="contained" sx={{ maxWidth: 35, minWidth: 35, maxHeight: 35, minHeight: 35 }} className="rounded-xl !bg-green-500 !px-2 !py-2 !text-white transition !duration-200 hover:!bg-green-600 active:!bg-green-700 dark:!bg-green-400 dark:!text-white dark:hover:!bg-green-300 dark:active:!bg-green-200">
                                  <FaUserCheck className="w-5 h-5 text-white" />
                                </Button>
                              </Tooltip>
                              :
                              <Tooltip title="suspender" arrow={true}>
                                <Button onClick={() => handleSuspended(venta)} disabled={(venta.state !== "complete" || isMutationVentas.uloading) ? true : false} variant="contained" sx={{ maxWidth: 35, minWidth: 35, maxHeight: 35, minHeight: 35 }} className="rounded-xl !bg-orange-500 !px-2 !py-2 !text-white transition !duration-200 hover:!bg-orange-600 active:!bg-orange-700 dark:!bg-orange-400 dark:!text-white dark:hover:!bg-orange-300 dark:active:!bg-orange-200">
                                  <FaUserClock className="w-5 h-5 text-white" />
                                </Button>
                              </Tooltip>
                          }

                          <Tooltip title="bloquear" arrow={true}>
                            <Button onClick={() => handleLockOrRejectedVenta(venta._id, 'locked', venta.state, CalcularExpiracion(venta.expirationdate))} disabled={(venta.state === 'rejected' || venta.state === 'pending' || isMutation.uloading || isMutationVentas.uloading) ? true : false} variant="contained" sx={{ maxWidth: 35, minWidth: 35, maxHeight: 35, minHeight: 35 }} className="rounded-xl !bg-red-500 !px-2 !py-2 !text-white transition !duration-200 hover:!bg-red-600 active:!bg-red-700 dark:!bg-red-400 dark:!text-white dark:hover:!bg-red-300 dark:active:!bg-red-200">
                              <FaLock className="w-5 h-5 text-white" />
                            </Button>
                          </Tooltip>
                        </div>
                      </div>
                    )
                  }
                })
              }
            </ul>
          </>
        }

      </div>
    </form>
  );
};
export default UpdateCuenta;
