import { useEffect, useMemo, useState } from "react"
import Spinner from "../../components/Spinner";
import debounce from 'lodash.debounce';
import { MdCategory, MdDelete, MdDevices, MdEdit, MdInventory, MdPhoneIphone } from "react-icons/md";
import { Button, IconButton, TextField, Tooltip } from "@mui/material";
import { IoChevronForward, IoCloseSharp, IoEye, IoPodium, IoSave, IoSearch, IoServer, IoTime } from "react-icons/io5";
import { currencyFormat, message, setUnixToTimeFormat } from "../../utils/Funciones.js";
import Swal from 'sweetalert2'
import { FaBoxes, FaCertificate, FaCogs, FaDollarSign, FaEdit, FaPencilAlt, FaPlus, FaShippingFast, FaTrashAlt, FaUserTie } from "react-icons/fa";
import { SiNano } from "react-icons/si";
import Wrapper from "./Wrapper";
import UpdateSubCategoryGC from "./UpdateSubCategoryGC";
import { Link } from "react-router-dom";
import SpinnerData from "../../components/SpinnerData";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import ImagesPreview from "../../components/ImagesPreview";
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from "draftjs-to-html";
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useSelector, useDispatch } from "react-redux";
import { deleteCategory, deleteSubCategory, updateCategory, SET_TO_DEFAULT_RESPONSE, getCategories, getSubCategories } from "../../store/features/slices/categorySlice";
import { HexColorPicker } from "react-colorful";
import Modal from "../../components/modal/Modal"
import { Input, InputGroup, InputLeftElement, InputRightElement } from "@chakra-ui/react";
import { FiSearch } from "react-icons/fi";
import { FaBoltLightning, FaBoxesStacked, FaEye, FaRegCalendarPlus } from "react-icons/fa6";
import Socket, { authSocket, logoutSocket } from '../../utils/Socket';
import ImagesPreview2 from "../../components/ImagesPreview2";
import Switch from "../../components/switch";

const CategoriesGC = ({ }) => {
   const dispatch = useDispatch();
   const {
      categories,
      subcategories,
      isMutation
   } = useSelector((state) => state.categoryReducer);

   const [searchCategories, SetSearchCategories] = useState("")
   const [openeditcategoryGC, SetOpenEditCategoryGC] = useState({ open: false, categoryId: "", categoryGCData: null })
   const [openVerSubcategorias, SetOpenVerSubcategorias] = useState({ open: false, name: "", categoryId: "" })
   const [updateCategoryState, setUpdateCategory] = useState({ _id: "", name: "", themecolor: "", description: "", enabled: false, open: false, currentlogo: "", currentsublogo: "", logo: "", sublogo: "" });
   const [color, setColor] = useState('#ffffff');
   const [enabled, setEnabled] = useState(false)
   const [preview, setPreview] = useState({
      logo: '',
      sublogo: ''
   })

   const [editorState, setEditorState] = useState(
      () => EditorState.createEmpty(),
   );

   var htmlData = useMemo(
      () => draftToHtml(convertToRaw(editorState.getCurrentContent())),
      [editorState]
   );

   const imageHandle = e => {
      if (e.target.files.length !== 0) {
         setUpdateCategory({ ...updateCategoryState, [e.target.name]: e.target.files[0] });
         const reader = new FileReader();
         reader.onloadend = () => {
            setPreview({ ...preview, [e.target.name]: reader.result })
         }
         reader.readAsDataURL(e.target.files[0]);
      }
   }

   const ondeleteCategory = id => {
      var CategoryIsUsed = false

      if (CategoryIsUsed) {
         message('Esta categoría ya está en uso y no se puede eliminar', 'error', 4)
      } else {
         alertaConfirmar('ELIMINAR CATEGORÍA', 'Estas seguro que quieres eliminar esta categoría? recuerda que se eliminarán todos los datos relacionados a esta categoría.', 'question', (res_alert) => {
            if (res_alert === true) {
               dispatch(deleteCategory(id));
            }
         })
      }
   }

   const alertaConfirmar = (titulo, texto, icon, callback) => {
      Swal.fire({
         title: titulo,
         html: texto,
         icon: icon,
         showCancelButton: true,
         confirmButtonText: 'Si',
         denyButtonText: 'No',
         cancelButtonText: 'Cancelar',
         confirmButtonColor: '#2C3E50',
         cancelButtonColor: '#CB4335'
      }).then((result) => {
         if (result.isConfirmed) {
            callback(result.isConfirmed)
         }
      })
   }

   const HandleFilterCategories = !isMutation.loading && categories.filter(_c => {
      return _c.name.toLowerCase().includes(searchCategories.toLowerCase()) && _c.type === 'GiftCard'
   })

   const HandleOpenEditCategoryGC = (categoryId, data) => {
      SetOpenEditCategoryGC({ ...openeditcategoryGC, open: true, categoryId: categoryId, categoryGCData: data });
   }

   const HandleCloseEditCategoryGC = () => {
      SetOpenEditCategoryGC({ ...openeditcategoryGC, open: false, categoryId: "", categoryGCData: null });
   }

   const HandleupdateCategory = () => {
      const contentState = editorState.getCurrentContent();
      updateCategoryState.themecolor = color
      if (contentState.hasText()) {
         if (updateCategoryState.name.trim().length < 1) {
            message('nombre de categoría es requerido', 'error', 4)
         } else {
            const formData = new FormData();
            formData.append('data', JSON.stringify(updateCategoryState));
            formData.append('rawData', JSON.stringify(convertToRaw(contentState)));
            formData.append('image1', updateCategoryState.logo)
            formData.append('image2', updateCategoryState.sublogo)
            dispatch(updateCategory(formData));
         }
      } else {
         message('Descripción y modo de uso es requerido', 'error', 4)
      }
   }

   const onOpenUpdateCategory = (description, name, enabled, themecolor, categoryId, currentlogo, currentsublogo) => {
      setColor(themecolor)
      setUpdateCategory({ ...updateCategoryState, description: description, name: name, enabled: enabled, _id: categoryId, open: !updateCategoryState.open, currentlogo: currentlogo, currentsublogo: currentsublogo })
      const rawContent = description;
      setEnabled(enabled)
      const contentState = convertFromRaw(JSON.parse(rawContent));
      const editorState = EditorState.createWithContent(contentState);
      setEditorState(editorState)
   }

   const onCloseupdateCategory = () => {
      setUpdateCategory({ ...updateCategoryState, _id: "", name: "", open: false, description: "", currentlogo: "", currentsublogo: "", logo: "", sublogo: "" })
      setPreview({ ...preview, logo: "", sublogo: "" })
   }

   const OnChangeEnabled = () => {
      setEnabled(!enabled)
      setUpdateCategory({ ...updateCategoryState, enabled: !enabled })
   }

   const handleDeleteSubCategory = (id) => {
      alertaConfirmar('ELIMINAR SUBCATEGORÍA', 'Estas seguro que quieres eliminar esta subcategoría? recuerda que se eliminarán todos los datos relacionados a esta subcategoría.', 'question', (res_alert) => {
         if (res_alert === true) {
            dispatch(deleteSubCategory(id))
         }
      })
   }

   const onOpenVerSubcategorias = (categoryId, name) => {
      SetOpenVerSubcategorias({ ...openVerSubcategorias, open: !openVerSubcategorias.open, categoryId: categoryId, name: name })
   }

   const onCloseVerSubcategorias = () => {
      SetOpenVerSubcategorias({ ...openVerSubcategorias, open: !openVerSubcategorias.open, categoryId: "", name: "" })
   }

   const onChangeColor = (event) => {
      setColor(event)
   };

   const debouncecolor = useMemo(() => debounce(onChangeColor, 1000), []);

   useEffect(() => {
      if (isMutation.success) {
         message(isMutation.message, 'success', 5)
         dispatch(SET_TO_DEFAULT_RESPONSE())
      } else {
         dispatch(getCategories())
         dispatch(getSubCategories())
      }
   }, [dispatch, isMutation.success])

   useEffect(() => {
      if (isMutation.updated) {
         message(isMutation.message, 'success', 5)
         openeditcategoryGC.open && Socket.emit('uSubCategory', { newData: isMutation.extra.subcategory })
         updateCategoryState.open && Socket.emit('uCategory', { newData: isMutation.extra.category })
         onCloseupdateCategory()
         HandleCloseEditCategoryGC()
         dispatch(SET_TO_DEFAULT_RESPONSE())
      }
   }, [isMutation.updated])

   const onChangeSearchTermClear = e => {
      SetSearchCategories("");
      document.getElementById("search").value = "";
   };

   return (
      <Wrapper>
         <Modal
            open={openeditcategoryGC.open}
            onClose={HandleCloseEditCategoryGC}
            headerTitle={`Editar subcategoría ${openeditcategoryGC.open && openeditcategoryGC.categoryGCData.name}`}
         >
            <div className="flex flex-wrap h-auto">
               <UpdateSubCategoryGC
                  categoryId={openeditcategoryGC.categoryId}
                  categoryGC={openeditcategoryGC.categoryGCData}
               />
            </div>
         </Modal>

         {/* HEADER PAGES */}
         <div className="fixed pl-3 md:pl-3 lg:pl-3 h-8 top-11 z-30 flex items-center w-8/12 text-brand-900 dark:text-brand-100 text-[1.2rem] md:text-[1.8rem] lg:text-[2rem] font-semibold">
            C. Tarjetas de regalo
            <div class="flex items-center justify-center px-3 ml-3 text-xl font-medium bg-brand-300 dark:bg-brand-800 text-brand-800 dark:text-brand-200 rounded-full">
               {!isMutation.loading && categories.filter(fil => fil.type === 'GiftCard').length}
            </div>
         </div>
         <div className="fixed mt-8 p-4 flex items-center text-brand-900 dark:text-brand-100 uppercase w-[calc(100%-1.50rem)] md:w-[calc(100%-1.50rem)] lg:w-[calc(100%-26.50rem)] font-semibold bg-white dark:bg-brand-900 backdrop-blur-lg z-20 rounded-b-xl">
            <div className="!w-9/12">
               <InputGroup className="w-full md:w-[450px] md:max-w-[450px]">
                  <InputLeftElement pointerEvents="none" className="!mt-[14px] !ml-2">
                     <FiSearch size={24} className="text-brand-900 dark:text-brand-100" />
                  </InputLeftElement>
                  <Input size='lg' autoFocus id="search" variant='filled' value={searchCategories} onChange={(e) => SetSearchCategories(e.target.value)} type="text" placeholder={`Buscar categorias...`} className="flex w-full md:w-[450px] md:max-w-[450px] !h-14 !pl-10 !items-center !justify-center !border-2 rounded-xl !bg-brand-200 
            dark:!bg-brand-950 !text-sm focus:!ring-lightPrimary dark:focus:!ring-darkPrimary focus:!border-lightPrimary dark:focus:!border-darkPrimary 
            !outline-none !border-brand-300 dark:!border-brand-800 !text-brand-900 dark:!text-white !relative !placeholder-brand-500" />
                  <InputRightElement className="!mt-[8px] !mr-1">
                     <IconButton onClick={onChangeSearchTermClear} className={`mr-2 ${searchCategories.trim().length > 0 ? 'scale-100' : 'scale-0'} transition-all duration-500 text-brand-600 dark:text-brand-300`}>
                        <IoCloseSharp size={20} className="text-brand-900 dark:text-brand-100" />
                     </IconButton>
                  </InputRightElement>
               </InputGroup>
            </div>
            <div className="!w-3/12 flex items-center justify-end">
               <div className="flex items-center justify-end">
                  <Link to="/admin/create-category" className="flex justify-end">
                     <Tooltip title="registrar categoría" arrow={true}>
                        <Button type="button" className="!bg-green-500" sx={{ maxWidth: 35, minWidth: 35, maxHeight: 35, minHeight: 35 }}>
                           <FaPlus size={45} className="!text-white" />
                        </Button>
                     </Tooltip>
                  </Link>
               </div>
            </div>
         </div>

         <Modal
            open={updateCategoryState.open}
            onClose={onCloseupdateCategory}
            headerTitle={`Editar categoría ${updateCategoryState.name}`}
         >
            <div className="flex flex-wrap h-auto">
               <div className="w-full mb-5">
                  <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Nombre de la categoría</label>
                  <input autoFocus name="name" className="custom-style-input"
                     autoComplete="off"
                     required
                     value={updateCategoryState.name}
                     type="text"
                     onChange={(e) => setUpdateCategory({ ...updateCategoryState, name: e.target.value })}
                  />
               </div>

               <div class="w-full md:w-6/12">

                  <label htmlFor="logo" class="flex flex-col items-center justify-center w-full h-[250px] border-2 border-brand-500 border-dashed rounded-lg cursor-pointer bg-brand-100 dark:bg-brand-900 hover:bg-brand-200 dark:hover:bg-brand-700">
                     <div class="flex flex-col items-center justify-center pt-5 pb-6 text-center">
                        <svg aria-hidden="true" class="w-10 h-10 mb-3 text-brand-900 dark:text-brand-200" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                        <p class="mb-2 text-sm text-brand-900 dark:text-brand-200"><span class="font-semibold">Click para subir imagen logo </span> o arrastrar y soltar</p>
                        <p class="text-xs text-brand-900 dark:text-brand-200">PNG o JPG (MAX. 800x400px)</p>
                     </div>
                     <input id="logo" accept="image/png,image/jpeg,image/webp" type="file" name="logo" class="hidden" onChange={imageHandle} />

                  </label>

               </div>
               <div className="border-2 mb-5 w-full md:w-6/12 h-[250px] rounded-lg border-brand-500 border-dashed flex inline-flex items-center justify-center">
                  <div className="">
                     <LazyLoadImage
                        className="object-contain w-[150px] h-[220px] z-24 flex inline-flex"
                        alt={`No image`}
                        effect="opacity"
                        src={`/images/logos/${updateCategoryState.currentlogo}`}
                     />
                  </div>
                  {preview.logo && <IoChevronForward className="text-brand-300" size={32} />}
                  <ImagesPreview2 url={preview.logo} />
               </div>
               <div className="w-full flex flex-wrap">
                  <div class="w-full md:w-6/12">

                     <label htmlFor="sublogo" class="flex flex-col items-center justify-center w-full h-[250px] border-2 border-brand-500 border-dashed rounded-lg cursor-pointer bg-brand-100 dark:bg-brand-900 hover:bg-brand-200 dark:hover:bg-brand-700">
                        <div class="flex flex-col items-center justify-center pt-5 pb-6 text-center">
                           <svg aria-hidden="true" class="w-10 h-10 mb-3 text-brand-900 dark:text-brand-200" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                           <p class="mb-2 text-sm text-brand-900 dark:text-brand-200"><span class="font-semibold">Click para subir imagen sublogo </span> o arrastrar y soltar</p>
                           <p class="text-xs text-brand-900 dark:text-brand-200">PNG o JPG (MAX. 800x400px)</p>
                        </div>
                        <input id="sublogo" accept="image/png,image/jpeg,image/webp" type="file" name="sublogo" class="hidden" onChange={imageHandle} />

                     </label>

                  </div>
                  <div className="border-2 w-full mb-5 md:w-6/12 h-[250px] rounded-lg border-brand-500 border-dashed flex inline-flex items-center justify-center">
                     <div className="">
                        <LazyLoadImage
                           className="object-cover w-[150px] h-[150px] z-24 rounded-full flex inline-flex"
                           alt={`No image`}
                           effect="opacity"
                           src={`/images/logos/${updateCategoryState.currentsublogo}`}
                        />
                     </div>
                     {preview.sublogo && <IoChevronForward className="text-brand-300" size={32} />}
                     <ImagesPreview url={preview.sublogo} />
                  </div>
               </div>

               <div className="w-full">
                  <label className="text-brand-900 dark:text-brand-200 text-sm">Habilitado ? <span className="font-semibold">{enabled ? "Si" : "No"}</span></label>
                  <div className="h-10 mt-3">
                     <Switch
                        value={enabled}
                        onChange={OnChangeEnabled}
                     />
                  </div>
               </div>

               <div className="w-[125px] mb-5 mt-5">
                  <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Color tema</label>
                  <HexColorPicker color={color} onChange={debouncecolor} />
               </div>

               <div className="w-full mb-5">
                  <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Descripción y modo de uso</label>
                  <Editor
                     editorState={editorState}
                     onEditorStateChange={setEditorState}
                     placeholder="Escribe aquí..."
                  />
               </div>

               <div className="w-full mt-10">
                  <Button
                     onClick={HandleupdateCategory}
                     sx={{
                        "&.Mui-disabled": {
                           background: "#525252",
                           color: "#a3a3a3"
                        }
                     }}
                     disabled={isMutation.loading ? true : false}
                     type="button" style={{ width: '100%', height: 65, marginLeft: 'auto', marginRight: 'auto' }}
                     size='large' variant="contained" color="primary">
                     {isMutation.loading ? <Spinner /> : <IoSave size={24} className="mr-2" />}
                     {isMutation.loading ? 'Guardando...' : 'ACTUALIZAR CATEGORIA'}
                  </Button>
               </div>
            </div>
         </Modal>



         <Modal
            open={openVerSubcategorias.open}
            onClose={onCloseVerSubcategorias}
            headerTitle={`Subcategorías de ${openVerSubcategorias.name}`}
         >
            <div className="flex flex-wrap h-auto">
               {
                  subcategories.filter((fil) => fil.category?._id === openVerSubcategorias.categoryId).length > 0 ? subcategories.filter((fil) => fil.category?._id === openVerSubcategorias.categoryId).sort((a, b) => a.created_at - b.created_at).map((cat, index) => {
                     return (
                        <div key={index} className="relative mt-3 p-5 w-full shadow-xl shadow-neutral-200 dark:shadow-none md:w-12/12 bg-white dark:bg-brand-900 text-brand-900 dark:text-brand-200 flex flex-wrap mb-10 rounded-lg animation">
                           <label style={{ filter: !cat.enabled ? 'grayscale(100%)' : 'none' }} className="absolute rounded-tl-lg rounded-tr-lg flex items-center left-0 -top-6 gap-3 bg-white dark:bg-brand-900 p-2">
                              <div className="w-[calc(100%-60px)] flex items-center justify-center">
                                 <LazyLoadImage
                                    className="object-cover w-8 h-8 z-10 rounded-full flex inline-flex ml-5"
                                    alt={`No image ${cat.name}`}
                                    effect="opacity"
                                    src={`/images/locations/${cat.location}.svg`}
                                 />
                              </div>
                              <div className={`flex items-center justify-center text-xl w-[60px] ${cat.stock > 0 ? 'text-green-500' : 'text-red-500'}`}>
                                 <MdInventory size={24} className="mr-1" /> {cat.stock}
                              </div>
                           </label>

                           <div style={{ filter: !cat.enabled ? 'grayscale(100%)' : 'none' }} className="flex items-center w-full md:w-full lg:w-[calc(41.6%-118px)] relative mt-5 md:mt-0">
                              <div className="flex items-center">
                                 <LazyLoadImage
                                    className="object-cover min-w-14 min-h-14 max-w-14 max-h-14 z-10 flex inline-flex rounded-lg"
                                    alt={`No image ${cat.name}`}
                                    effect="opacity"
                                    src={`/images/logos/${cat.logo}`}
                                 />

                              </div>
                              <div className="w-[calc(100%-48px)]">
                                 <div className="ml-2 text-sm font-semibold text-brand-900 dark:text-brand-200 truncate ...">{cat.name}</div>
                                 <div className="ml-2 text-brand-900 dark:text-brand-200 text-xs mt-2 truncate ...">{setUnixToTimeFormat('created_at', cat.created_at)}</div>
                              </div>

                           </div>

                           <div style={{ filter: !cat.enabled ? 'grayscale(100%)' : 'none' }} className="w-full md:w-6/12 lg:w-3/12 grid mt-5 md:mt-5 lg:mt-0">
                              <div className="flex items-center justify-center text-sm uppercase">Costo usuario</div>
                              <div className="flex items-center justify-center">
                                 <div class="text-center text-green-950 dark:text-green-50 bg-gradient-to-r from-green-400 dark:from-green-500 rounded-l-lg flex items-center p-2">
                                    {
                                       cat.discountuser > 0 ?
                                          <div>
                                             <span className="line-through mr-2">
                                                {currencyFormat(cat.costouser)}
                                             </span>
                                             {currencyFormat(cat.costouser - ((cat.costouser * cat.discountuser) / 100))}
                                          </div>
                                          :
                                          currencyFormat(cat.costouser)
                                    }
                                 </div>
                              </div>
                           </div>

                           <div style={{ filter: !cat.enabled ? 'grayscale(100%)' : 'none' }} className="w-full md:w-6/12 lg:w-2/12 grid mt-5 md:mt-5 lg:mt-0">
                              <div className="flex items-center justify-center text-sm uppercase">Costo reseller</div>
                              <div className="flex items-center justify-center">
                                 <div class="text-center text-amber-950 dark:text-amber-50 bg-gradient-to-r from-amber-400 dark:from-amber-500 rounded-l-lg flex items-center p-2">
                                    {currencyFormat(cat.costoreseller)}
                                 </div>
                              </div>
                           </div>


                           <div style={{ filter: !cat.enabled ? 'grayscale(100%)' : 'none' }} className="w-full md:w-full lg:w-2/12 grid mt-5 md:mt-5 lg:mt-0">
                              <div className="flex items-center justify-center text-sm uppercase">Tipo de entrega</div>
                              <div className="flex items-center justify-center">
                                 {cat.tipoentrega ?
                                    <div class="text-center text-amber-950 dark:text-amber-50 bg-gradient-to-r from-amber-400 dark:from-amber-500 rounded-l-lg flex items-center p-2">
                                       <FaBoltLightning size={24} className="mr-1" />Automática
                                    </div>
                                    :
                                    <div class="text-center text-cyan-950 dark:text-cyan-50 bg-gradient-to-r from-cyan-400 dark:from-cyan-500 rounded-l-lg flex items-center p-2">
                                       <FaCogs size={24} className="mr-1" />Manual
                                    </div>
                                 }
                              </div>
                           </div>

                           <div className="flex items-center gap-2 justify-end w-full md:w-full lg:w-[118px] mt-5 md:mt-5 lg:mt-0">
                              <Tooltip title="editar" arrow={true}>
                                 <Button onClick={() => HandleOpenEditCategoryGC(openVerSubcategorias.categoryId, cat)} variant="contained" sx={{ maxWidth: 35, minWidth: 35, maxHeight: 35, minHeight: 35 }} className="rounded-xl !bg-amber-500 !px-2 !py-2 !text-base font-medium !text-white transition !duration-200 hover:!bg-amber-600 active:!bg-amber-700 dark:!bg-amber-400 dark:!text-white dark:hover:!bg-amber-300 dark:active:!bg-amber-200">
                                    <FaPencilAlt className="w-5 h-5 text-white" />
                                 </Button>
                              </Tooltip>

                              <Tooltip title="eliminar" arrow={true}>
                                 <Button onClick={() => handleDeleteSubCategory(cat._id)} variant="contained" sx={{ maxWidth: 35, minWidth: 35, maxHeight: 35, minHeight: 35 }} className="rounded-xl !bg-red-500 !px-2 !py-2 !text-white transition !duration-200 hover:!bg-red-600 active:!bg-red-700 dark:!bg-red-400 dark:!text-white dark:hover:!bg-red-300 dark:active:!bg-red-200">
                                    <FaTrashAlt className="w-5 h-5 text-white" />
                                 </Button>
                              </Tooltip>
                           </div>
                        </div>)
                  }) :
                     <div class="flex items-center m-auto p-3 mb-3 text-sm text-yellow-800 rounded-lg bg-yellow-50 dark:bg-brand-900 dark:text-yellow-500" role="alert">
                        <svg class="flex-shrink-0 inline w-8 h-8 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                           <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                        </svg>
                        <span class="sr-only">Info</span>
                        <div>
                           <span class="font-bold uppercase">Sin datos!</span> La categoría <b>{openVerSubcategorias.name}</b> aún no tiene datos registrados!
                        </div>
                     </div>
               }
            </div>
         </Modal>
         <div className="relative w-full">
            {!isMutation.loading ? HandleFilterCategories.length > 0 ? <>
               <div className="flex flex-wrap mt-[155px]">


                  {HandleFilterCategories.map((category) => {

                     return (
                        <div className={`p-4 w-full shadow-xl shadow-neutral-200 dark:shadow-none cursor-pointer md:w-12/12 bg-white dark:bg-brand-900 text-brand-900 dark:text-brand-200 flex flex-wrap mb-10 rounded-lg animation`} key={category._id}>

                           <div style={{ filter: !category.enabled ? 'grayscale(100%)' : 'none' }} className="grid grid-wrap w-full md:w-full lg:w-4/12">
                              <div className="flex inline-flex items-center">
                                 <LazyLoadImage
                                    className="object-contain w-24 h-32 z-10 flex inline-flex"
                                    alt={`No image ${category.name}`}
                                    effect="opacity"
                                    src={`/images/logos/${category.logo}`}
                                 />
                                 <LazyLoadImage
                                    className="object-cover w-12 h-12 z-10 rounded-full flex inline-flex ml-2"
                                    alt={`No image ${category.name}`}
                                    effect="opacity"
                                    src={`/images/logos/${category.sublogo}`}
                                 />
                                 <div className="grid gap-1 self-center">
                                    <div className="ml-2 text-sm text-brand-900 dark:text-brand-200 font-semibold uppercase">{category.name}</div>
                                    <div className="ml-2 flex items-center text-xs mt-2 truncate ..."><FaRegCalendarPlus size={16} className='mr-[5px] min-w-[20px] min-h-[20px]' /><label className="truncate ...">{setUnixToTimeFormat('created_at', category.created_at)}</label></div>
                                 </div>
                              </div>
                           </div>

                           <div style={{ filter: !category.enabled ? 'grayscale(100%)' : 'none' }} className="w-full md:w-full lg:w-2/12 grid mt-8 md:mt-0">
                              <div className="flex items-center justify-center text-sm">COLOR TEMA</div>
                              <div className="flex items-center justify-center">
                                 <div className="flex inline-flex items-center text-2xl font-semibold">
                                    <div className="w-8 h-8 rounded-full border-2 border-brand-300 dark:border-brand-700" style={{ background: `${category.themecolor}` }}>
                                    </div>
                                 </div>
                              </div>
                           </div>

                           <div style={{ filter: !category.enabled ? 'grayscale(100%)' : 'none' }} className="w-full md:w-full lg:w-2/12 grid mt-3 md:mt-0">
                              <div className="flex items-center justify-center text-sm">SUBCATEGORIAS</div>
                              <div className="flex items-center justify-center">
                                 <div className="flex inline-flex items-center text-2xl font-semibold">
                                    {subcategories.filter((fil) => fil.category?._id === category._id).length}
                                 </div>
                              </div>
                           </div>

                           <div className="flex items-center gap-2 justify-end w-full md:w-full lg:w-4/12">
                              <Tooltip title="ver subcategorias" arrow={true}>
                                 <Button onClick={() => onOpenVerSubcategorias(category._id, category.name)} variant="contained" sx={{ maxWidth: 35, minWidth: 35, maxHeight: 35, minHeight: 35 }} className="rounded-xl !bg-indigo-500 !px-2 !py-2 !text-white transition !duration-200 hover:!bg-indigo-600 active:!bg-indigo-700 dark:!bg-indigo-400 dark:!text-white dark:hover:!bg-indigo-300 dark:active:!bg-indigo-200">
                                    <FaEye className="w-5 h-5 text-white" />
                                 </Button>
                              </Tooltip>

                              <Tooltip title="editar" arrow={true}>
                                 <Button onClick={() => onOpenUpdateCategory(category.description, category.name, category.enabled, category.themecolor, category._id, category.logo, category.sublogo)} variant="contained" sx={{ maxWidth: 35, minWidth: 35, maxHeight: 35, minHeight: 35 }} className="rounded-xl !bg-amber-500 !px-2 !py-2 !text-base font-medium !text-white transition !duration-200 hover:!bg-amber-600 active:!bg-amber-700 dark:!bg-amber-400 dark:!text-white dark:hover:!bg-amber-300 dark:active:!bg-amber-200">
                                    <FaPencilAlt className="w-5 h-5 text-white" />
                                 </Button>
                              </Tooltip>

                              <Tooltip title="eliminar" arrow={true}>
                                 <Button onClick={() => ondeleteCategory(category._id)} variant="contained" sx={{ maxWidth: 35, minWidth: 35, maxHeight: 35, minHeight: 35 }} className="rounded-xl !bg-red-500 !px-2 !py-2 !text-white transition !duration-200 hover:!bg-red-600 active:!bg-red-700 dark:!bg-red-400 dark:!text-white dark:hover:!bg-red-300 dark:active:!bg-red-200">
                                    <FaTrashAlt className="w-5 h-5 text-white" />
                                 </Button>
                              </Tooltip>
                           </div>
                        </div>
                     )
                  }
                  )}
               </div></> : <div className="alert-danger font-semibold mt-[155px]">Sin resultados en la búsqueda! o no existen categorías registradas</div> : <div className="mt-[175px]"><SpinnerData>Cargando categorías giftcard, por favor espere...</SpinnerData></div>}
         </div>
      </Wrapper>
   )
}
export default CategoriesGC;