import React, { useRef, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Transition from '../utils/Transition';
import { useDispatch, useSelector } from 'react-redux';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { IoEllipse } from 'react-icons/io5';
import { OPEN_FROM_SEARCH } from '../store/features/slices/searchSlice';
import { MenuItem } from '@mui/material';
import { RiVipCrownFill } from 'react-icons/ri';

function CardVip({ vip }) {
    const dispatch = useDispatch();
    const navigate = useNavigate()

    return (
        <div>
            {
                (vip?.type === 'VIP1' && vip?.state === 'ACTIVE' && new Date(vip?.expirationdate) > new Date()) ?
                    <div className="relative bg-gradient-to-t from-[#553617] to-[#CD7F32] inline-block text-transparent bg-clip-text text-2xl font-bold">
                        VIP BRONCE
                        <RiVipCrownFill className="absolute -right-3 -top-3 text-[#CD7F32] rotate-45 opacity-80" />
                    </div>
                    :
                    (vip?.type === 'VIP2' && vip?.state === 'ACTIVE' && new Date(vip?.expirationdate) > new Date()) ?
                        <div className="relative bg-gradient-to-t from-[#808080] to-[#C0C0C0] inline-block text-transparent bg-clip-text text-2xl font-bold">
                            VIP PLATA
                            <RiVipCrownFill className="absolute -right-3 -top-3 text-[#C0C0C0] rotate-45 opacity-80" />
                        </div>
                        : (vip?.type === 'VIP3' && vip?.state === 'ACTIVE' && new Date(vip?.expirationdate) > new Date()) ?
                            <div className="relative bg-gradient-to-t from-[#816e00] to-[#FFD700] inline-block text-transparent bg-clip-text text-2xl font-bold">
                                VIP ORO
                                <RiVipCrownFill className="absolute -right-5 -top-4 text-[#FFD700] rotate-45 opacity-80" />
                            </div>
                            :
                            'No tienes ninguna subscripción VIP'
            }
        </div>
    );
}

export default CardVip;