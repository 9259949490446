import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import cuentaService from "../services/cuentaService";
import { message } from "../../../utils/Funciones";

const initialState = {
    isMutation: { success: false, error: false, loading: false, message: '', updated: false, uloading: false, extra: {} },
    contador: { profilesExpired: 0, proxExpired: 0, expired: 0, locked: 0, total: 0, loading: false },
    cuentasByPage: [],
    cuentas: [],
    totalFilter: 0,
    total: 0,
    currentPage: 1,
    totalPages: 1,
    verifyCodeEmails: []
};

export const create = createAsyncThunk(
    "cuenta/create",
    async (formData, thunkAPI) => {
        try {
            return await cuentaService.create(formData);
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const getAll = createAsyncThunk(
    "cuenta/getAll",
    async (_, thunkAPI) => {
        try {
            return await cuentaService.getAll();
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const getBypage = createAsyncThunk(
    "cuenta/getBypage/cuentasbypage",
    async (query, thunkAPI) => {
        try {
            return await cuentaService.getBypage(query);
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const verificarCodigo = createAsyncThunk(
    "cuenta/verificarCodigo",
    async (query, thunkAPI) => {
        try {
            return await cuentaService.verificarCodigo(query);
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const contador = createAsyncThunk(
    "cuenta/contador/contador",
    async (_, thunkAPI) => {
        try {
            return await cuentaService.contador();
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const update = createAsyncThunk(
    "cuenta/update/cuenta",
    async (formData, thunkAPI) => {
        try {
            return await cuentaService.update(formData);
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const deleteCuenta = createAsyncThunk(
    "cuenta/deleteCuenta/cuenta",
    async (id, thunkAPI) => {
        try {
            return await cuentaService.deleteCuenta(id);
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

const cuentaSlice = createSlice({
    name: "cuenta",
    initialState,
    reducers: {
        SET_TO_DEFAULT_RESPONSE(state, action) {
            state.isMutation = { success: false, error: false, loading: false, message: '', updated: false, uloading: false, extra: {} }
        },

        UPDATE_CUENTA_EXTERNAL(state, action) {
            const newState = state.cuentasByPage.map((cuenta) => {
                if (cuenta._id === action.payload.cuenta._id) {
                    cuenta = action.payload.cuenta
                }
                return cuenta
            })
            state.contador.profilesExpired = action.payload.newcontadorprofilesexpired
            state.cuentasByPage = newState
        },

        UPDATE_CUENTA_FROM_SOCKET(state, action) {

        },
    },
    extraReducers: (builder) => {
        builder

            // CREATE

            .addCase(create.pending, (state) => {
                state.isMutation.loading = true;
            })
            .addCase(create.fulfilled, (state, action) => {
                state.isMutation.message = action.payload.message;
                state.isMutation.extra.subcategory = action.payload.subcategory;
                state.isMutation.loading = false;
                state.isMutation.success = true;
            })
            .addCase(create.rejected, (state, action) => {
                state.isMutation.loading = false;
            })

            // GET ALL

            .addCase(getAll.pending, (state) => {
                state.isMutation.loading = true;
            })
            .addCase(getAll.fulfilled, (state, action) => {
                state.isMutation.loading = false;
                state.cuentas = action.payload;
            })
            .addCase(getAll.rejected, (state, action) => {
                state.isMutation.loading = false;
            })

            // GET BYPAGE

            .addCase(getBypage.pending, (state) => {
                state.isMutation.loading = true;
            })
            .addCase(getBypage.fulfilled, (state, action) => {
                state.isMutation.loading = false;
                state.cuentasByPage = action.payload.cuentas;
                state.totalFilter = action.payload.totalcuentasFilter;
                state.total = action.payload.totalcuentas;
                state.currentPage = action.payload.currentPage;
                state.totalPages = action.payload.totalPages;
            })
            .addCase(getBypage.rejected, (state, action) => {
                state.isMutation.loading = false;
            })

            // CONTADOR

            .addCase(contador.pending, (state) => {
                state.contador.loading = true
            })
            .addCase(contador.fulfilled, (state, action) => {
                state.contador.profilesExpired = action.payload.totalcuentaswhitallprofilesexpired;
                state.contador.proxExpired = action.payload.totalcuentasProxExpired;
                state.contador.expired = action.payload.totalcuentasExpired;
                state.contador.locked = action.payload.totalcuentasLocked;
                state.contador.total = action.payload.totalcuentas;
                state.contador.loading = false
            })
            .addCase(contador.rejected, (state, action) => {
                state.contador.loading = false
            })

            // UPDATE

            .addCase(update.pending, (state) => {
                state.isMutation.uloading = true;
            })
            .addCase(update.fulfilled, (state, action) => {
                state.isMutation.message = action.payload.message;
                const newState = state.cuentasByPage.map((cuenta) => {
                    if (cuenta._id === action.payload.newData._id) {
                        cuenta = action.payload.newData
                    }
                    return cuenta
                })
                state.cuentasByPage = newState
                state.isMutation.extra.affectSubCategory = action.payload.affectSubCategory;
                state.isMutation.extra.subcategory = action.payload.subcategory;
                state.isMutation.uloading = false;
                state.isMutation.updated = true;
            })
            .addCase(update.rejected, (state, action) => {
                state.isMutation.uloading = false;
            })

            // VERIFY CODE
            .addCase(verificarCodigo.pending, (state) => {
                state.isMutation.loading = true;
            })
            .addCase(verificarCodigo.fulfilled, (state, action) => {
                state.verifyCodeEmails = action.payload.verifyCodeEmails
                state.isMutation.loading = false;
                state.isMutation.success = true
            })
            .addCase(verificarCodigo.rejected, (state, action) => {
                state.isMutation.loading = false;
            })

            // DELETE

            .addCase(deleteCuenta.pending, (state) => {
                state.isMutation.loading = true;
            })
            .addCase(deleteCuenta.fulfilled, (state, action) => {
                state.isMutation.message = action.payload.message;
                state.isMutation.extra.subcategory = action.payload.newData
                state.isMutation.loading = false;
                state.isMutation.success = true;
            })

            .addCase(deleteCuenta.rejected, (state, action) => {
                state.isMutation.loading = false;
            })
    },
});

export const { UPDATE_CUENTA_FROM_SOCKET, SET_TO_DEFAULT_RESPONSE, UPDATE_CUENTA_EXTERNAL } = cuentaSlice.actions;

export default cuentaSlice.reducer;

