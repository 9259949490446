
import { applyTransparency, currencyFormat, generateBackgroundColor, message, sensurarEmail, sensurarString } from "../../utils/Funciones.js";
import { IoCart, IoFlashSharp } from "react-icons/io5";
import { Button, Tooltip } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import Spinner from "../Spinner";
import { FcKey } from "react-icons/fc";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import draftToHtml from "draftjs-to-html";
import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
import parse from 'html-react-parser';
import { FaClipboardCheck, FaMinus, FaPlus, FaUser } from "react-icons/fa6";
import AnimatedNumber from "react-animated-numbers";
import Modal from "../../components/modal/Modal"
import moment from 'moment';
import 'moment/locale/es';
import { SET_CASH_USER } from "../../store/features/slices/userSlice";
import { SET_TO_DEFAULT_RESPONSE, create } from "../../store/features/slices/gcventaSlice";
import { SET_TO_DEFAULT_RESPONSE as SET_TO_DEFAULT_RESPONSE_SEARCH } from "../../store/features/slices/searchSlice";
import { UPDATE_LAST10_OF_CATEGORY, UPDATE_SUBCATEGORY } from "../../store/features/slices/categorySlice";
import Socket, { authSocket, logoutSocket } from '../../utils/Socket';
import { MdSecurity } from "react-icons/md";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CardVip from "../CardVip.jsx";
import { RiVipCrownFill } from "react-icons/ri";

const CategoriesGiftCard = ({ socket, comprarVip }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userToken, user, userInfo } = useSelector((state) => state.userReducer);
  const { categories, subcategories, isMutation } = useSelector((state) => state.categoryReducer);
  const {
    isMutation: isMutationVenta
  } = useSelector((state) => state.gcventaReducer);
  const { isMutation: isMutationSearch } = useSelector((state) => state.searchReducer);

  const [selectgiftcard, setSelectGiftCard] = useState({ open: false, categoryId: "", name: "", sublogo: "", description: "" })
  const [editorState3, setEditorState3] = useState(EditorState.createEmpty());
  var htmlData3 = useMemo(
    () => draftToHtml(convertToRaw(editorState3.getCurrentContent())),
    [editorState3]
  );

  const [purchaseGC, SetPurchaseGC] = useState({
    catId: "",
    subcatId: "",
    open: false,
    namesubGC: "",
    userId: "",
    priceunit: 0,
    totalamount: 0,
    logo: "",
    QtyGC: 0,
    currentstock: 0,
    location: "",
    isADMIN: false,
    action: 'create',
    _id: "",
    observation: ""
  })

  useEffect(() => {
    if (isMutationVenta.success) {
      message(isMutationVenta.message, 'success', 5)
      dispatch(SET_CASH_USER({ type: 'DECREASE', amount: isMutationVenta.extra.amount }))
      dispatch(UPDATE_LAST10_OF_CATEGORY(isMutationVenta.extra.newHistory))
      if (isMutationVenta.extra.newDataSubcategory.tipoentrega) {
        Socket.emit('uSubCategoryWhitCategory', { newData: isMutationVenta.extra.newDataSubcategory, newHistory: isMutationVenta.extra.newHistory })
        dispatch(UPDATE_SUBCATEGORY({ newData: isMutationVenta.extra.newDataSubcategory }))
      }
      dispatch(SET_TO_DEFAULT_RESPONSE())
      navigate('/manage-account/mygiftcards');
    }
  }, [dispatch, isMutationVenta.success])

  const onIncreaseGC = (e) => {
    if (Number(purchaseGC.QtyGC) < purchaseGC.currentstock)
      SetPurchaseGC({ ...purchaseGC, QtyGC: Number(purchaseGC.QtyGC) + 1, totalamount: purchaseGC.priceunit * (purchaseGC.QtyGC + 1) })

  }

  const onDecreaseGC = (e) => {
    if (Number(purchaseGC.QtyGC) > 0)
      SetPurchaseGC({ ...purchaseGC, QtyGC: Number(purchaseGC.QtyGC) - 1, totalamount: purchaseGC.priceunit * (purchaseGC.QtyGC - 1) })
  }

  const OpenPurchaseGiftCards = (subcat) => {
    if (userToken) {
      var costo = 0
      var currentstock = 0
      var isVip = false
      var discountvip = 0
      if (userInfo?.vip?.state === 'ACTIVE' && new Date(userInfo?.vip?.expirationdate) > new Date()) {
        isVip = true
        discountvip = subcat.discountvip
      }

      if (!userInfo?.reseller) {
        costo = Number((subcat.costouser) - (((subcat.costouser) * subcat.discountuser) / 100)) - discountvip
      } else {
        costo = Number(subcat.costoreseller) - discountvip
      }

      if (subcat.tipoentrega) {
        currentstock = subcat.stock
      } else {
        currentstock = 10
      }

      setSelectGiftCard({ ...selectgiftcard, categoryId: "", open: false, name: "", sublogo: "", description: "" })

      SetPurchaseGC({
        ...purchaseGC,
        catId: subcat.category?._id,
        subcatId: subcat._id,
        open: true,
        userId: user?.id,
        logo: subcat.logo,
        namesubGC: subcat.name,
        priceunit: costo,
        currentstock: currentstock,
        location: subcat.location
      })
    } else {
      navigate('/login');
    }
  }

  const openOptions = (category) => {
    setSelectGiftCard({ ...selectgiftcard, categoryId: category._id, open: true, name: category.name, description: category.description, sublogo: category.sublogo })

    const rawContent = category.description;
    const contentState = convertFromRaw(JSON.parse(rawContent));
    const editorState = EditorState.createWithContent(contentState);
    setEditorState3(editorState)
  }

  const PayPurchaseGC = (e) => {
    e.preventDefault()
    if (userToken) {
      if (purchaseGC.QtyGC > 0) {
        dispatch(create(purchaseGC));
      } else {
        message('La cantidad de tarjetas de regalo debe ser mayor a 0', 'error', 4)
      }
    } else {
      navigate('/login');
    }
  }

  useEffect(() => {
    if (isMutationSearch.open) {
      if (isMutationSearch.data.category?.type === 'GiftCard') {
        OpenPurchaseGiftCards(isMutationSearch.data)
        dispatch(SET_TO_DEFAULT_RESPONSE_SEARCH())
      }
    }
  }, [isMutationSearch.open])

  var settings = {
    centerMode: true,
    infinite: true,
    centerPadding: "8px",
    slidesToShow: 3,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 2000,
    pauseOnHover: false,
    nextArrow: <></>,
    prevArrow: <></>
  };

  var settings2 = {
    centerMode: true,
    infinite: true,
    centerPadding: "0px",
    slidesToShow: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 2000,
    pauseOnHover: false,
    nextArrow: <></>,
    prevArrow: <></>
  };

  return (

    <>
      <Modal
        open={purchaseGC.open}
        onClose={() => SetPurchaseGC({ ...purchaseGC, currentstock: 0, open: false, QtyGC: 0, totalamount: 0, priceunit: 0, location: "" })}
        headerTitle={`Comprar ${purchaseGC.namesubGC}`}
        size="base"
      >

        <div className="flex flex-wrap">
          <div className="w-full text-brand-100">
            <div className="w-full flex items-center border-b-brand-300 dark:border-b-brand-800 mb-3">
              <label className="text-lg text-brand-900 dark:text-brand-200">Información de la compra</label>
            </div>
            <div className="w-full p-5 text-brand-100 bg-brand-200 dark:bg-brand-800">
              <div className="grid grid-cols-2 gap-4">
                <div className="flex items-center">
                  <div className="border-2 border-brand-300 dark:border-brand-700 bg-brand-50 dark:bg-brand-950 w-20 h-20 flex items-center justify-center">
                    <LazyLoadImage
                      className="object-cover w-16 h-16 z-30 rounded-full p-2"
                      alt={`No image category`}
                      effect="opacity"
                      src={`/images/locations/${purchaseGC.location}.svg`}
                    />
                  </div>
                  <div className="pl-3 text-brand-900 dark:text-brand-200">{purchaseGC.location}</div>
                </div>

                <div className="flex items-center">
                  <div className="border-2 border-brand-300 dark:border-brand-700 bg-brand-50 dark:bg-brand-950 w-20 h-20 flex items-center justify-center">
                    <FcKey size={45} />
                  </div>
                  <div className="pl-3 text-brand-900 dark:text-brand-200">Key</div>
                </div>
              </div>
            </div>
          </div>

          <form className="w-full" onSubmit={PayPurchaseGC}>
            <div className="w-full mt-5">
              <label className="text-lg text-brand-900 dark:text-brand-200">Cuántas tarjetas de regalo quieres comprar ? min: 1 - max: 10</label>
              <div className="relative w-full flex items-center justify-center text-2xl mt-3 p-3">
                <img className="w-64 object-cover"
                  src={`/images/logos/giftcard-ui.png`} alt={`No image giftcard-ui`}>
                </img>
                <div style={{ fontFamily: 'Outfit, sans-serif' }} className="absolute top-[90px] flex items-center text-xl text-brand-300">
                  TARJETA DE REGALO
                </div>
                <div className="absolute flex items-center">
                  <Tooltip title="quitar" arrow={true}>
                    <Button onClick={() => onDecreaseGC()} disabled={Number(purchaseGC.QtyGC) > 0 ? false : true} variant="contained" sx={{ maxWidth: 65, minWidth: 65, maxHeight: 65, minHeight: 65 }} className="rounded-xl !bg-red-500 !px-2 !py-2 !text-white transition !duration-200 hover:!bg-red-600 active:!bg-red-700 dark:!bg-red-400 dark:!text-white dark:hover:!bg-red-300 dark:active:!bg-red-200">
                      <FaMinus className="w-14 h-14 text-white" />
                    </Button>
                  </Tooltip>

                  <div style={{ fontFamily: 'Outfit, sans-serif' }} className="w-[40px] text-3xl mr-2 ml-2 text-center flex items-center justify-center text-brand-300">
                    <AnimatedNumber
                      animateToNumber={purchaseGC.QtyGC}
                      includeComma
                      config={{ tension: 89, friction: 40 }}
                      onStart={() => console.log("onStart")}
                      onFinish={() => console.log("onFinish")}
                      animationType={"calm"}
                      duration={200}
                    />
                  </div>
                  <Tooltip title="añadir" arrow={true}>
                    <Button onClick={() => onIncreaseGC()} disabled={Number(purchaseGC.QtyGC) < purchaseGC.currentstock ? false : true} variant="contained" sx={{ maxWidth: 65, minWidth: 65, maxHeight: 65, minHeight: 65 }} className="rounded-xl !bg-green-500 !px-2 !py-2 !text-white transition !duration-200 hover:!bg-green-600 active:!bg-green-700 dark:!bg-green-400 dark:!text-white dark:hover:!bg-green-300 dark:active:!bg-green-200">
                      <FaPlus className="w-14 h-14 text-white" />
                    </Button>
                  </Tooltip>
                </div>
                <div style={{ fontFamily: 'Outfit, sans-serif' }} className="absolute bottom-14 flex items-center text-4xl text-brand-200">
                  {currencyFormat(purchaseGC.totalamount)}
                </div>
              </div>

            </div>
            <div className="w-full mt-5">
              <Button disabled={(isMutationVenta.loading || purchaseGC.QtyGC) < 1 ? true : false}
                sx={{
                  "&.Mui-disabled": {
                    background: "#525252",
                    color: "#a3a3a3"
                  }
                }}
                type="submit" style={{ width: '100%', height: 65, marginLeft: 'auto', marginRight: 'auto' }}
                size='large' variant="contained" color="primary" startIcon={isMutationVenta.loading ? <Spinner w={'35px'} h={'35px'} /> : <IoCart size={24} />}>{isMutationVenta.loading ? 'Procesando...' : `Pagar ${currencyFormat(purchaseGC.totalamount)}`} </Button>
            </div>
          </form>
        </div>
      </Modal>

      <Modal
        open={selectgiftcard.open}
        onClose={() => setSelectGiftCard({ ...selectgiftcard, categoryId: "", open: false, name: "", sublogo: "", description: "" })}
        headerTitle={`${selectgiftcard.name}`}
        size="normal"
      >
        <div className="flex flex-wrap p-5 relative">
          <div className="w-full md:w-full lg:w-5/12 flex items-center justify-center">
            <div className="w-full h-88 md:w-88 flex items-center justify-center">
              <LazyLoadImage
                className="w-72 h-72 z-10 rounded-[2.9rem]"
                alt={`No image ${selectgiftcard.name}`}
                effect="opacity"
                src={`/images/logos/${selectgiftcard.sublogo}`}
              />
            </div>
          </div>
          <div className="w-full md:w-7/12 text-brand-900 dark:text-brand-200">
            <div className="uppercase text-xl font-bold mt-6 md:mt-10 text-left">TARJETAS DE REGALO {selectgiftcard.name}</div>
            <div className="w-full flex-wrap mt-16">
              {
                !isMutation.loading && subcategories.filter((fil) => fil.category?._id === selectgiftcard.categoryId && fil.enabled).length > 0 ? subcategories.filter((fil) => fil.category?._id === selectgiftcard.categoryId && fil.enabled).sort((a, b) => (Math.floor(new Date(a.created_at).getTime() / 1000)) - (Math.floor(new Date(b.created_at).getTime() / 1000))).map((gift) => {
                  var discountvip = 0
                  var isVip = false

                  if (userInfo?.vip?.state === 'ACTIVE' && new Date(userInfo?.vip?.expirationdate) > new Date()) {
                    isVip = true
                    discountvip = gift?.discountvip
                  }
                  return (
                    <div className="group shadow-lg shadow-brand-300 dark:shadow-black relative w-full flex items-center mb-20 p-2 bg-white dark:bg-brand-900 transition-all duration-500">

                      {/*
                        gift.tipoentrega && gift.stock === 0 &&
                        <div style={{ fontFamily: 'Outfit, sans-serif' }} className="price-tag absolute z-40 top-0 left-[50%] group-hover:opacity-0 transition-all duration-500 flex justify-center items-center">
                          MOMENTANEAMENTE<br></br>
                          SIN STOCK
                        </div>*/
                      }

                      {
                        isVip ?
                          <div className="absolute -top-12 z-20 left-3 grid justify-center">
                            <CardVip vip={userInfo?.vip} />
                            <div className="text-xs text-center">Ahorras {currencyFormat(discountvip)}</div>
                          </div>
                          :
                          <div className="absolute -top-12 z-20 left-3 grid justify-center">
                            <div className="relative bg-gradient-to-t from-lightPrimary/40 dark:from-darkPrimary/40 to-lightPrimary dark:to-darkPrimary inline-block text-transparent bg-clip-text text-2xl font-bold">
                              CON VIP
                              <RiVipCrownFill className="absolute -right-5 -top-3 text-lightPrimary dark:text-darkPrimary rotate-45" />
                            </div>
                            <div className="text-xs text-center">Ahorras {currencyFormat(discountvip)}
                              <label onClick={() => (comprarVip(), setSelectGiftCard({ ...selectgiftcard, categoryId: "", open: false, name: "", sublogo: "", description: "" }))} className="ml-4 text-[12px] text-red-400 dark:text-red-500 cursor-pointer">comprar VIP</label>
                            </div>
                          </div>
                      }

                      <div className="absolute bottom-3 right-2 ">
                        <div className="flex flex-wrap text-sm text-left uppercase font-bold">
                          {
                            gift.tipoentrega ?
                              <div className={`${gift.stock > 0 ? 'text-green-600 dark:text-green-400' : 'text-red-600 dark:text-red-400'}`}>{gift.stock > 0 ? <div class="text-center text-green-950 dark:text-green-50 bg-gradient-to-r from-green-400 dark:from-green-600 rounded-l-lg flex items-center p-2">disponible</div> : <div class="text-center text-red-950 dark:text-red-50 bg-gradient-to-r from-red-400 dark:from-red-600 rounded-l-lg flex items-center p-2">no disponible</div>}</div> : <div class="text-center text-green-950 dark:text-green-50 bg-gradient-to-r from-green-400 dark:from-green-600 rounded-l-lg flex items-center p-2">disponible</div>
                          }
                        </div>
                      </div>

                      <div className="absolute right-0 -top-7 flex items-center text-sm bg-white dark:bg-brand-900 p-2">
                        <LazyLoadImage
                          className="w-7 h-7 z-10 mr-1"
                          alt={`No image ${gift.location}`}
                          effect="opacity"
                          src={`/images/locations/${gift.location}.svg`}
                        />
                        Región {gift.location}
                      </div>

                      <div className="flex flex-wrap w-[calc(100%-125px)] relative">
                        <div className="w-full font-semibold uppercase pt-1 pb-1 whitespace-nowrap">{gift.name}</div>
                        <div style={{ fontFamily: 'Outfit, sans-serif' }} className="w-full md:w-6/12 pt-1 pb-1 text-lg">
                          {
                            !userInfo?.reseller ?
                              <div className="text-green-600 dark:text-green-500 font-bold">
                                {(gift.costouser) - (((gift.costouser) * gift.discountuser) / 100) === 0 ?
                                  <div class="text-center text-blue-950 dark:text-blue-50 bg-gradient-to-r from-blue-400 dark:from-blue-500 rounded-l-lg flex items-center p-2">GRATIS</div>
                                  :
                                  gift.discountuser > 0 ?
                                    <div class="text-center text-red-950 dark:text-red-50 bg-gradient-to-r from-red-400 dark:from-red-500 rounded-l-lg flex items-center p-2">{gift.discountuser > 0 && <span className="mr-3 line-through">{currencyFormat(gift.costouser)}</span>}{currencyFormat((gift.costouser) - (((gift.costouser) * gift.discountuser) / 100) - discountvip)}</div>
                                    :
                                    <div class="text-center text-green-950 dark:text-green-50 bg-gradient-to-r from-green-400 dark:from-green-500 rounded-l-lg flex items-center p-2">{gift.discountuser > 0 && <span className="mr-3 line-through">{currencyFormat(gift.costouser)}</span>}{currencyFormat((gift.costouser) - (((gift.costouser) * gift.discountuser) / 100) - discountvip)}</div>
                                }
                              </div>
                              :
                              <div class="text-center text-amber-950 dark:text-amber-50 bg-gradient-to-r from-amber-400 dark:from-amber-500 rounded-l-lg flex items-center p-2">{currencyFormat(gift.costoreseller - discountvip)}</div>
                          }
                        </div>
                        <div className="w-full pt-1 pb-1">
                          {
                            gift.tipoentrega ?
                              <div className="w-full flex flex-wrap gap-2">
                                <div className="w-full flex items-center text-xs">
                                  <div className="bg-green-600 text-white max-w-[25px] min-w-[25px] max-h-[25px] min-h-[25px] rounded-full flex items-center justify-center p-1 mr-2"><IoFlashSharp size={16} className="ml-[1px]" /></div><div className="text-brand-900 dark:text-brand-200 font-semibold">Entrega digital instantánea</div>
                                </div>
                                <div className="w-full flex items-center text-xs">
                                  <div className="bg-blue-600 text-white max-w-[25px] min-w-[25px] max-h-[25px] min-h-[25px] rounded-full flex items-center justify-center p-1 mr-2"><MdSecurity size={16} className="ml-[1px]" /></div><div className="text-brand-900 dark:text-brand-200 font-semibold">Garantía 30 días</div>
                                </div>
                              </div>
                              :
                              <div className="w-full flex flex-wrap gap-2">
                                <div className="w-full flex items-center text-xs">
                                  <div className="bg-amber-600 text-white max-w-[25px] min-w-[25px] max-h-[25px] min-h-[25px] rounded-full flex items-center justify-center p-1 mr-2"><FaClipboardCheck size={16} /></div><div className="text-brand-900 dark:text-brand-200 font-semibold">Entrega digital 10-30 minutos</div>
                                </div>
                                <div className="w-full flex items-center text-xs">
                                  <div className="bg-blue-600 text-white max-w-[25px] min-w-[25px] max-h-[25px] min-h-[25px] rounded-full flex items-center justify-center p-1 mr-2"><MdSecurity size={16} className="ml-[1px]" /></div><div className="text-brand-900 dark:text-brand-200 font-semibold">Garantía 30 días</div>
                                </div>
                              </div>
                          }
                        </div>
                      </div>

                      <div className="flex flex-wrap w-[125px]">
                        <div className="w-full md:w-[125px] flex justify-end">
                          <Button
                            disabled={(gift.stock > 0 || !gift.tipoentrega) ? false : true}
                            onClick={() => OpenPurchaseGiftCards(gift)}
                            sx={{
                              "&.Mui-disabled": {
                                background: "#525252",
                                color: "#a3a3a3"
                              }, fontSize: '12px !important', paddingLeft: '1px !important', paddingRight: '1px !important'
                            }}
                            type="button" style={{ width: 115, height: 50, marginLeft: 'auto', marginRight: 'auto' }}
                            size='large' variant="contained" color="primary" startIcon={<IoCart size={22} />}>
                            {(gift.stock > 0 || !gift.tipoentrega) ? 'Comprar' : 'Sin stock'}</Button>
                        </div>
                      </div>
                    </div>
                  )
                }) :
                  <div class="flex items-center p-3 mb-3 text-sm text-yellow-800 rounded-lg bg-yellow-50 dark:bg-brand-800 dark:text-yellow-500" role="alert">
                    <svg class="flex-shrink-0 inline w-8 h-8 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                    </svg>
                    <span class="sr-only">Info</span>
                    <div>
                      <span class="font-bold uppercase">Sin datos!</span> La categoría <b>{selectgiftcard.name}</b> aún no tiene datos registrados!
                    </div>
                  </div>
              }
            </div>
          </div>
          <div className="w-full flex flex-wrap mt-8 text-brand-900 dark:text-brand-200">
            <div className="w-full border-b border-brand-300 dark:border-brand-800 flex flex-wrap mb-2">
              <div className="-mb-[1px] bg-brand-50 dark:bg-brand-950 border-t border-l border-r border-brand-300 dark:border-brand-800 p-2 flex items-center text-blue-600 dark:text-blue-300">Descripción y modo de uso</div>
            </div>
            <div style={{ whiteSpace: 'pre-wrap' }} className="w-full flex flex-wrap">
              <div>{parse(htmlData3)}</div>
            </div>
          </div>

        </div>
      </Modal>

      <div style={{ fontFamily: 'Outfit, sans-serif' }} className="w-full 
       text-brand-900 dark:text-brand-100
        mb-3 mt-4 md:mt0 text-center text-lg md:text-xl pt-5 pb-5 md:pt-14 md:pb-14 contrast-[125%]">
        <div style={{ fontFamily: 'Outfit, sans-serif' }}>SERVICIOS</div>
        <div className="text-4xl md:text-6xl bg-gradient-to-t from-brand-800 to-blue-500 inline-block text-transparent bg-clip-text">
          GIFTCARD
        </div>
      </div>
      <div className="flex flex-wrap w-full pb-10">
        <div className="w-full min-h-[22rem] grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-x-3 md:gap-x-8 gap-y-8 md:gap-y-14 mb-4">
          {
            !isMutation.loading && categories.filter(fil => fil.type === 'GiftCard' && fil.enabled).map((category, index) => {
              var isVip = false
              var discountvip = 0
              if (userInfo?.vip?.state === 'ACTIVE' && new Date(userInfo?.vip?.expirationdate) > new Date()) {
                isVip = true
                discountvip = subcategories.filter((fil) => fil.category?._id === category._id)[0]?.discountvip
              }

              return (
                <div onClick={() => openOptions(category)} key={index} className="relative shadow-lg shadow-brand-300 dark:shadow-black bg-white dark:bg-brand-950 group w-full min-h-[300px] h-[300px] md:h-[310px] lg:h-[320px] overflow-hidden rounded-xl transition-transform ease-in-out duration-700 md:hover:scale-[115%] cursor-pointer">
                  <div style={{ backgroundColor: `${applyTransparency(category.themecolor, 0.2)}` }} class="absolute w-56 h-48 blur-[90px] -left-1/2 -top-1/2"></div>
                  <div style={{ backgroundColor: `${applyTransparency(category.themecolor, 0.2)}` }} class="absolute w-56 h-48 blur-[90px] -right-1/2 -top-1/2"></div>
                  <div style={{ backgroundColor: `${category.themecolor}` }} class="absolute w-72 h-48 blur-[50px] -right-1/2 -bottom-1/2"></div>
                  <div className="absolute z-30 right-2 top-2 opacity-0 transform translate-y-10 group-hover:opacity-100 group-hover:translate-y-0 transition duration-500 ease-in-out">
                    <LazyLoadImage
                      className="object-cover max-w-[65px] min-w-[65px] max-h-[65px] min-h-[65px] rounded-full z-10 transition-all ease-in-out duration-700 [box-shadow:0px_1px_5px_1px_var(--tw-shadow-color)] shadow-black dark:shadow-black"
                      alt={`No image ${category.name}`}
                      effect="opacity"
                      src={`/images/logos/${category.sublogo}`}
                    />
                  </div>
                  <LazyLoadImage

                    className="h-[calc(100%-6.5rem)] w-[calc(100%-2px)] z-5 absolute ml-[1px]"
                    alt={`No image ${category.name}`}
                    effect="opacity"
                    src={`/images/logos/${category.logo}`}
                  />
                  <div className="absolute h-full w-full top-0">
                    <div class="relative w-full h-[calc(100%-9rem)] z-10">
                      {
                        subcategories.filter((fil) => fil.category?._id === category._id) &&
                        !userInfo?.reseller &&
                        Number(subcategories.filter((fil) => fil.category?._id === category._id && fil.discountuser > 0)[0]?.discountuser) > 0 &&
                        <div className="absolute w-[70px] p-1 text-center top-[2rem] left-0 z-30 price-tag2 group-hover:animate-pulse">
                          <span className="price">-{Number(subcategories.filter((fil) => fil.category?._id === category._id && fil.discountuser > 0)[0]?.discountuser)}%</span>
                        </div>
                      }
                    </div>
                    <div className="h-[9rem]">
                      <div style={{ backgroundColor: `${applyTransparency(category.themecolor, 0.1)}` }} className="relative">
                        <div class="relative w-full h-[2rem] z-10 flex items-center justify-center">
                          {
                            subcategories.filter((fil) => fil.category?._id === category._id)[0] &&
                            <div className="text-brand-50 !filter-none text-center font-bold text-[1.6rem] md:text-[1.8rem] lg:text-[2rem] [text-shadow:0px_3px_3px_var(--tw-shadow-color)] shadow-black dark:shadow-black">
                              {!userInfo?.reseller ? currencyFormat((Number(subcategories.filter((fil) => fil.category?._id === category._id)[0].costouser)) - (((Number(subcategories.filter((fil) => fil.category?._id === category._id)[0].costouser)) * Number(subcategories.filter((fil) => fil.category?._id === category._id)[0].discountuser)) / 100) - discountvip) : currencyFormat(Number(subcategories.filter((fil) => fil.category?._id === category._id)[0].costoreseller) - discountvip)}
                            </div>
                          }
                        </div>
                        <div class="relative w-full h-[2rem] z-10 flex items-center justify-center text-brand-50 text-[0.9rem] md:text-[1rem] lg:text-[1.1rem] uppercase font-semibold [text-shadow:0px_3px_3px_var(--tw-shadow-color)] shadow-black dark:shadow-black">
                          {category.name}
                        </div>
                      </div>
                      <div class="relative w-full h-[4.2rem] z-10 flex items-center justify-center bg-white dark:bg-brand-950">
                        {
                          category.history.length >= 3 &&
                          <div className="flex flex-wrap justify-center w-full">
                            <Slider className="!relative overflow-hidden !rounded-l-full !rounded-r-lg max-w-[95px] min-w-[95px] max-h-[2.5rem] min-h-[2.5rem] z-20" key={index} {...settings}>
                              {
                                category.history?.map((item) => {
                                  return (
                                    <>
                                      {
                                        item?.history?.user?.photo === 'unknowphoto.webp' ?
                                          <div style={{ background: `${generateBackgroundColor(item?.history?.user?.name)}` }} className="uppercase -ml-[8px] relative mr-4 size-[2.5rem] rounded-full flex items-center justify-center text-white text-xl font-normal border-2 border-white dark:border-brand-900 z-10" key={item._id}>
                                            {item?.history?.user?.name.substr(0, 1)}
                                          </div>
                                          :
                                          <div className="-ml-[8px] overflow-hidden z-10 relative mr-4 size-[2.5rem] rounded-full flex items-center justify-center" key={item._id}>
                                            <LazyLoadImage
                                              className="object-cover size-[2.5rem] rounded-full border-2 border-white dark:border-brand-900 z-10"
                                              alt={`No image ${item?.history?.user?.name}`}
                                              effect="opacity"
                                              src={`/images/photos/${item?.history?.user?.photo}`}
                                            />
                                          </div>
                                      }
                                    </>
                                  )
                                })
                              }
                            </Slider>
                            {
                              category.history?.length > 0 &&
                              <div className="-ml-[17px] z-30 relative size-[2.5rem] rounded-full border-2 border-white dark:border-brand-900 z-10 flex items-center justify-center bg-brand-900 dark:bg-brand-50">
                                <FaUser size={22} className="text-brand-50 dark:text-brand-900" />
                              </div>
                            }

                            <Slider className="w-[calc(95%)] z-20 !m-auto h-[20px]" key={category._id} {...settings2}>
                              {
                                category.history?.map((item) => {
                                  return (
                                    <div className="relative text-[9px] font-normal text-center text-black dark:text-white px-1 overflow-hidden" key={item._id}>
                                      {sensurarEmail(item?.history?.user?.email)} {moment(item?.history?.created_at).fromNow()}
                                    </div>)
                                })
                              }
                            </Slider>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              )
            })
          }
        </div>
      </div >
    </>
  );
};

export default CategoriesGiftCard;
